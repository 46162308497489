/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import axios from "axios";
import { PriceData } from "../Pricing/Pricing";
import { SettingsData } from "../Settings/Settings";
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import Notification from "../Notifications/Notification";


interface ContainerProps {
  isMinimized: boolean;
  isProjectSelected: boolean;
}

interface ProjectComponentProps {
  backgroundColor: string;
  isPremium: boolean;
}

const NewButton = styled.button`
  background-color: transparent;
  display: flex;
  border: none;
  color: white;
  font-size: 100%;
  margin-left: 5%;


   @media (max-height: 350px) {
    font-size: 80%;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
  }
    @media (max-height: 350px){
    font-size: 2.8vh;
    }
`
const ProjectContainer = styled.div`
  position: fixed;
  margin-left: 2%;
  display: flex;
  top: 0.2rem;      
  padding-top: 0rem;
  flex-direction: row;
@media (min-width: 1900px){
width: 100vh;
gap: 25vh;}
 @media (max-height: 350px) {
    position: absolute; /* Change to absolute for small screens */
    top: 0; /* Align to the top */
    right: auto; /* Align to the right */
    left: 20vh; /* Ensure no left alignment */
    width: auto; /* Auto width */
   
  }
  `

const CreateProjectContainer = styled.div`
display: flex;
gap: 4%;
margin-right: 2%;
max-width: 90%;  
flex-wrap: nowrap;

`;

const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  height: 90%;
  @media (max-width: 1000px) {
   overflow-y: hidden; 
   overflow-z: hidden; 
  }
`;


const CostContainer = styled.div<ContainerProps>`
  display: ${(props) => (props.isMinimized ? "none" : "hidden")};
  flex-direction: row;
  background-color: transparent;
  height: ${(props) => (props.isMinimized ? "0px" : "80%")};
  top: ${(props) => (props.isMinimized ? "2.5rem" : "unset")};
  opacity: ${props => props.isProjectSelected ? 1 : 0.7};
  z-index: ${(props) => (props.isMinimized ? "999" : "unset")};
  margin-top: 0.7rem;
  height: fit-content;
  transition: height 0.3s ease;

`;

const ResultContainer = styled.div<ContainerProps>`
display: flex;
flex-direction: column;
background-color: transparent;
width: ${(props) => (props.isMinimized ? "fit-content" : "100%")};
overflow-y: ${(props) => (props.isMinimized ? "hidden" : "auto")};
height: ${(props) => (props.isMinimized ? "28px" : "80%")};
opacity: ${props => props.isProjectSelected ? 1 : 0.5};
position: ${(props) => (props.isMinimized ? "absolute" : "unset")};
top: ${(props) => (props.isMinimized ? "0rem" : "unset")};
left: ${(props) => (props.isMinimized ? "100%" : "unset")};
z-index: ${(props) => (props.isMinimized ? "1" : "unset")};
@media (max-height: 350px){
  top: ${(props) => (props.isMinimized ? "1.5rem" : "unset")};
  margin-top: 0%;
  margin-right: 2%;
  height: ${(props) => (props.isMinimized ? "20px" : "100%")};
}

`;

const ContainerWrapper = styled.div<ContainerProps>`
display: flex;
flex-direction: column;
flex: 1;
height: ${(props) => (props.isMinimized ? "28px" : "100%")};
flex-basis: 10%;
margin-right: 3rem;
opacity: ${props => props.isProjectSelected ? 1 : 0.5};
position: ${(props) => (props.isMinimized ? "absolute" : "unset")};
top: ${(props) => (props.isMinimized ? "2.5rem" : "unset")};
left: ${(props) => (props.isMinimized ? "70%" : "unset")};
z-index: ${(props) => (props.isMinimized ? "1" : "unset")};
@media (max-height: 350px){
  top: ${(props) => (props.isMinimized ? "0rem" : "unset")};
  width: 15%;
  margin-top: 0.3%;
  margin-right: 2%;
  left: ${(props) => (props.isMinimized ? "50%" : "unset")};
  height: ${(props) => (props.isMinimized ? "20px" : "100%")};
}
`;


const ResultTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  right: 0px;
`;

const ResultTableHeader = styled.th`
  padding-top: 1%;
  padding-bottom: 1%;
  text-align: left;
  height: 3px;
  border-bottom: 1px solid black;
  font-size: 0.9rem;
  color: black;
  background-color: rgba(249,249,249);
  @media (max-height: 350px){
    padding: 2px;
    font-size: 70%;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
  }   
  
`;

const ResultTableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(249,249,249);
  }
  background-color: rgba(170,179,184);
  height: fit-content;
  width: 100%;
  color: black;
  font-family: 'barlow';
  font-weight: 500;
  @media (max-height: 350px){
    font-size: 80%;
  }
     @media (min-width: 1900px){

    font-size: 2vh;
  }
`;

interface ResultTableDataProps {
  updated?: Boolean
}

const ResultTableDataOrig = styled.td<ResultTableDataProps>`
  padding: 0.15rem;
  border: 1px solid black;
  background-color: ${(props) => (props.updated ? 'rgba(0, 158, 228, 0.3)' : 'transparent')}; // Example updated color
  font-size: 0.8rem;
  
  height: fit-content;
  @media (max-height: 350px){
    font-size: 80%;
  }
    @media (min-width: 1900px){

    font-size: 2vh;
  }
`;

const ResultTableData = styled.td<ResultTableDataProps>`
  padding: 0.15rem;
  border: 1px solid black;
  background-color: ${(props) => (props.updated ? 'rgba(0, 158, 228, 0.3)' : 'transparent')}; // Example updated color
  font-size: 0.8rem;
  width: 8vh;
  height: fit-content;
  @media (max-height: 350px){
    font-size: 80%;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
  }
`;

const ResultTableDataCost = styled.td<ResultTableDataProps>`
  padding: 0.15rem;
  border: 1px solid black;
  background-color: ${(props) => (props.updated ? 'rgba(0, 158, 228, 0.3)' : 'transparent')}; // Example updated color
  font-size: 0.8rem;
  height: fit-content;
  @media (max-height: 350px){
    font-size: 80%;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
  }

`;

const ResultTableDataText = styled.td<ResultTableDataProps>`
  padding: 0.15rem;
  border: 1px solid black;
  background-color: ${(props) => (props.updated ? 'rgba(0, 158, 228, 0.3)' : 'transparent')}; // Example updated color
  font-size: 0.8rem;
  width: 12vh;
  height: fit-content;
  @media (max-height: 350px){
    font-size: 80%;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
  }
`;

const ValueContainer = styled.div<ContainerProps>`
display: flex;
flex-direction: column;
background-color: transparent;
flex: 1;
flex-grow: 1;
flex-basis: 5%;
overflow-y: ${(props) => (props.isMinimized ? "hidden" : "auto")};
overflow-x: hidden;
height: ${(props) => (props.isMinimized ? "28px" : "100%")};
width: 20%;
margin-right: 3rem;
opacity: ${props => props.isProjectSelected ? 1 : 0.5};
position: ${(props) => (props.isMinimized ? "absolute" : "unset")};
top: ${(props) => (props.isMinimized ? "2.5rem" : "unset")};
left: ${(props) => (props.isMinimized ? "10%" : "unset")};
z-index: ${(props) => (props.isMinimized ? "1" : "unset")};

@media (max-height: 350px){
  top: ${(props) => (props.isMinimized ? "1.5rem" : "unset")};
  width: 15%;
  margin-top: 0.3%;
  margin-right: 2%;
  height: ${(props) => (props.isMinimized ? "20px" : "100%")};
}
`;


const LabourContainer = styled.div<ContainerProps>`
display: flex;
flex-direction: column;
background-color: transparent;
flex: 1;
flex-basis: 5%;
overflow-y: ${(props) => (props.isMinimized ? "hidden" : "auto")};
height: ${(props) => (props.isMinimized ? "28px" : "100%")};
width: 20%;
margin-right: 3rem;
opacity: ${props => props.isProjectSelected ? 1 : 0.5};
position: ${(props) => (props.isMinimized ? "absolute" : "unset")};
top: ${(props) => (props.isMinimized ? "2.5rem" : "unset")};
left: ${(props) => (props.isMinimized ? "40%" : "unset")};
z-index: ${(props) => (props.isMinimized ? "1" : "unset")}; /* Set a higher z-index when minimized to move it above other containers */
@media (max-height: 350px){
  top: ${(props) => (props.isMinimized ? "1.5rem" : "unset")};
  margin-top: 0.3%;
  width: 20%;
  margin-right: 2%;
  height: ${(props) => (props.isMinimized ? "20px" : "100%")};
}
`;

const MinimizeButton = styled.button`
margin-right: 10%; 
border: none;
float: right;
`;


const MaterialDropdown = styled(Dropdown)`
  display: inline-block; /* Change to inline-block */
  vertical-align: middle; /* Vertically align the dropdown */
  width: 35px;
  margin-right: 0.5rem;
  height: 5vh;
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  width: fit-content;
  height: fit-content;
  padding: 0.5vh; 
  color: white;
  align-items: center; 
`;

const InputResult = styled.input`
  width: 6vh;
  color: black;
  text-align: left;
  border: none;
  background-color: transparent;
  text-align: right;
  margin-right: 0.5vh;
  

  /* Disable spinners */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
  
`;

const ButtonWrapper = styled.div`
  height: fit-content;
  @media (max-height: 350px) {
   height: fit-content; 
  }
`;


const Input = styled.input`
  width: 32px;
  color: black;
  border: none;
  border-radius: 0.2rem;
  background-color: rgba(52, 52, 52, 0.3);
  text-align: right;
  margin-right: 0.2vh;

  /* Disable spinners */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  @media (max-height: 350px) {
    font-size: 100%;
    width: 25px;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
  }
`;


const Button = styled.button`
  margin-right: 5%;
  background-color: white;
  width: fit-content;
  font-size: 0.9rem;
  color: black;
  border-radius: 3px;
  border: none;
  @media (max-height: 350px) {
    font-size: 80%;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
  }
`;

const ProjectButton = styled.button`
  background-color: white;
  width: fit-content;
  font-size: 0.9rem;
  color: black;
  border-radius: 3px;
  border: none;
  margin-right: 150px;
  @media (max-height: 350px) {
    margin-right: 90px;
    font-size: 2.8vh;
  }
`;

const ActionsContainer = styled.div`
  margin-top: 0px;
  
  @media (max-width: 768px) {
   
  }

  .icon {
    cursor: pointer;
    vertical-align: middle;
  }

  .download-icon {
    margin-right: 5px;
  }

  .trash-icon {
    margin-left: 5px;
  }
`;

const SelectDropdown = styled.select`
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 0.3rem;
  color: white;
  outline: none;
  width: fit-content;
  font-size: 90%;

  @media (max-height: 350px) {
    width: 100px;
    font-size: 70%;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
  }
`;

const ProjectNameInput = styled.input`
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 0.3rem;
  color: white;
  outline: none;
  width: 150px;

  @media (max-height: 350px) {
    font-size: 80%;
    width: 90px;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
    width: 20vh;
  }
    @media (max-height: 350px){
    font-size: 2.9vh;}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  @media (max-width: 1000px) {
    overflow-y: hidden;
    overflow-z: hidden; 
   }
`;

const TableSeparatorRow = styled.tr`
  height: 5px; /* Adjust the height as needed */
  background-color: transparent; /* Adjust the color as needed */
`;

const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  @media (max-height: 350px){
    margin-right: 5%;
  }
`;

const ActionContainer = styled.div`
position: relative;
  display: flex;
  color: white;
  flex-direction: row;
  align-items: center;
  height: 10%;
  justify-content: space-between;

  @media (min-width: 1900px ){
  margin-left: 5%
  }
`;

const PriceSpan = styled.span`
    margin-left: 5%;
    font-style: italic;  // This will make the text italic
    font-size: 95%;  // Adjust to your desired size
`;

const TitleDiv = styled.div`
  width: 100%;
  background-color: black;
  color: white;
  padding-left: 1%;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: rgba(170,179,184);
}
`

const ColspanCell = styled.td`
    background-color: black;
    border: transparent;
    color: black;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        background-color: rgba(170,179,184);
    }
`;

const HighlightedButton = styled.button<{ highlighted: boolean }>`
  margin-right: 5%;
  background-color: white;
  width: fit-content;
  font-size: 0.9rem;
  color: black;
  border-radius: 3px;
  border: none;
  position: ${props => (props.highlighted ? 'fixed' : 'relative')}; /* Use fixed positioning when highlighted */
  bottom: ${props => (props.highlighted ? '8vh' : 'auto')};
  right: ${props => (props.highlighted ? '20px' : 'auto')};
  z-index: ${props => (props.highlighted ? 1001 : 'auto')}; /* Ensure it's above the modal overlay */

  @media (max-height: 350px) {
    font-size: 80%;
  }

  @media (min-width: 1900px) {
    font-size: 1.8vh;
  }
     @media (max-height: 350px) {
    font-size: 2.8vh;
  }
`;

const ErrorMessage = styled.div`
  color: #d8000c;
  margin: 20px 0;
  border-radius: 5px;
  text-align: center;
`;
    
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: absolute;
  background: white;
  padding: 1.5vh;
  border-radius: 10px;
  width: 35vh;
  font-size: 2vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  right: 5vh;
  bottom: 0vh;
  transform: translate(-25%, -20%);
  @media (min-width: 1900px){
  width: 28vh;}
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 0.5vh;
  margin-bottom: 1vh;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba(7,55,99);
    color: white;
  }
   @media (max-height: 350px) {
    font-size: 2.8vh;
  }
`;

interface UpdatedData {
  [key: string]: any;
}

interface UpdatedMaterial {
  materialName: string;
  displayName: string;
  quantity: number;
  price: string | number;
}


interface Project {
  _id: string;
  userId: string;
  name: string;
  projectName: string;
  materialPrices: PriceData;
}

interface FormData {
  checkedItems: boolean[];
  projectName: string;
  submitted: false;
  inputValue1: number;
  inputValue2: number;
  inputValue3: number;
  inputValue4: number;
  inputValue5: number;
  inputValue6: number;
  inputValue7: number;
  inputValue8: number;
  inputValue9: number;
  inputValue10: number;
  inputValue11: number;
  inputValue12: number;
  inputValue13: number;
  inputValue14: number;
  inputValue15: number;
  inputValue16: number;
  inputValue17: number;
  inputValue18: number;
  inputValue19: number;
  inputValue20: number;
  inputValue21: number;
  inputValue22: number;
  inputValue23: number;
  inputValue24: number;
  inputValue25: number;
  inputValue26: number;
  inputValue27: number;
  inputValue28: number;
  inputValue29: number;
  inputValue30: number;
  inputValue31: number;
  inputValue32: number;
  inputValue33: number;
  inputValue34: number;
  inputValue35: number;
  inputValue36: number;
  inputValue37: number;
  inputValue38: number;
  inputValue39: number;
  inputValue40: number;
  inputValue41: number;
  inputValue42: number;
  inputValue43: number;
  inputValue44: number;
  inputValue45: number;
  inputValue46: number;
  inputValue47: number;
  inputValue48: number;
  inputValue49: number;
  inputValue50: number;
  inputValue51: number;
  inputValue52: number;
  inputValue53: number;
  inputValue54: number;
  inputValue55: number;
  inputValue56: number;
  inputValue57: number;
  inputValue58: number;
  inputValueSetts: number;
  pavingWasteCalc: string;
  blockPavingCalc: string;
  bPavingWasteCalc: string;
  pavingHardcoreCalc: string;
  bPavingHardcoreCalc: string;
  postCalc: string;
  sandCalc: string;
  cementCalc: string;
  adhesiveCalc: string;
  panelCalc: string;
  pavingWasteCalc0: string;
  ballastCalc: string;
  footingCementCalc: string;
  blockworkCalc: string;
  wallMetreCalc: string;
  brickworkCalc: string;
  buildingSandCalc: string;
  brickSandCalc: string;
  tileAdhesiveCalc: string;
  sleeperCalc: string;
  postcreteCalc: string;
  soilCalc: string;
  pavingCalc: string;
  gravelCalc: string;
  wasteCalc: string;
  hardcoreCalc: string;
  pavingPrice: string;
  bPavingPrice: string;
  membraneCalc: string;
  labourPrice: string;
  marginPrice: string;
  pointingCalc: string;
  pavingCementCalc: string;
  totalWasteCalc: string;
  footingCalc: string;
  edgingCalc: string;
  claddingCalc: string;
  deckingCalc: string;
  stoneFacingCalc: string;
  renderCalc: string;
  fasciaCalc: string;
  frameworkCalc: string;
  fixingScrewCalc: string;
  deckingScrewCalc: string;
  concreteCalc: string;
  pavingBedCalc: string;
  concreteDepth: string;
  concretePadCalc: string;
  curbCalc: string;
  rebarCalc: string;
  pavingDepth: string;
  projectPrice: string;
  blockPavingScreed: string;
  barkCalc: string;
  bPavingDepth: string;
  totalCost2: string;
  pavingHardcore: string;
  bPavingHardcore: string;
  buildingSandCement: string;
  pavingCementBags: string;
  concretePadHardcore: string;
  pavingScreedCalc: string;
  artificialTurfScreed: string;
  edgingWasteCalc: string;
  footingWaste: string;
  settsPointingCalc: string;
  pavingPointing: string;
  settsHardcore: string;
  edgingHardcore: string;
  settsWaste: string;
  kilnSand: string;
  settsCementBags: string;
  settsCalc: string;
  artiCalc: string;
  turfCalc:string;
  diggerCalc:string;
  dumperCalc:string;
  channelDrainCalc: string;
  pipeCalc:string;
  gullyCalc:string;
  fittingsCalc: string;
  manholeCoverCalc: string;
  febCalc: string;
  asphaltHardcore: string;
  asphaltWaste: string;
  [key: string]: any;
}

interface UserAddedItem {
  materialName: string;
  quantity: number;
  price: number;
  checked?: boolean;
}

interface NewMaterial {
  materialName: string;
  quantity: number;
  price: number;
}

interface FencingMaterial {
  name: string;
  width: number;
  materials: { materialName: string; quantity: number }[];
}

interface FencingMaterial {
  name: string;
  width: number;
  materials: Material[];
}

interface Material {
  materialName: string;
  quantity: number;
}

type ActionType = 'save' | 'download' | 'delete' | 'upload' | 'updatePrices' | 'updateSettings';
  

const ProjectComponent: React.FC<ProjectComponentProps> = ({ backgroundColor, isPremium }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [showMembraneDetails, setShowMembraneDetails] = useState(false);
  const [showScreedDetails, setShowScreedDetails] = useState(false);
  const [showHardcoreDetails, setShowHardcoreDetails] = useState(false);
  const [showWasteDetails, setShowWasteDetails] = useState(false);
  const [showCementDetails, setShowCementDetails] = useState(false);
  const [toggleUnusedValues, setToggleUnusedValues] = useState(false);
  const [showPointingDetails, setShowPointingDetails] = useState(false);
  const [uploadStatusMessage, setUploadStatusMessage] = useState('');
  const [uploadAttempted, setUploadAttempted] = useState(false);
  const [settingsData, setSettingsData] = useState<Partial<SettingsData>>({});
  const [projectName, setProjectName] = useState('');
  const [loadedData, setLoadedData] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [projectNameInput, setProjectNameInput] = useState('');
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [manualInput, setManualInput] = useState(false);
  const [materialData, setMaterialData] = useState<PriceData>({});
  const [typing, setTyping] = useState(Boolean);
  const [changesMade, setChangesMade] = useState(false);
  const [isValueMinimized, setIsValueMinimized] = useState(false);
  const [isLabourMinimized, setIsLabourMinimized] = useState(false);
  const [isResultMinimized, setIsResultMinimized] = useState(false);
  const table1Ref = useRef<HTMLTableElement | null>(null);
  const table2Ref = useRef<HTMLTableElement | null>(null);
  const [saveStatus, setSaveStatus] = useState<string>("");
  const [isCopied, setIsCopied] = useState(false);
  const [userAddedItems, setUserAddedItems] = useState<UserAddedItem[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [highlightButton, setHighlightButton] = useState(false);

  const [newMaterial, setNewMaterial] = useState({
  materialName: '',
  quantity: 0,
  price: 0,
});
  const [shouldRecalculate, setShouldRecalculate] = useState(false);
  const [isMaterialDataError, setIsMaterialDataError] = useState(false);
  const [isSettingsDataError, setIsSettingsDataError] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({
    inputValue1: false,
    inputValue2: false,
    inputValue3: false,
    inputValueSetts: false,
    inputValue5: false,
    inputValue7: false,
    inputValue9: false,
    inputValue47: false,
    inputValue17: false,
    inputValue18: false,
    inputValue51: false,
    inputValue19: false,
    inputValue50: false,
    inputValue6: false,
    inputValue46: false,
    inputValue55: false,
    inputValue13: false,
    inputValue11: false,
    inputValue10: false,
    inputValue48: false,
    inputValue49: false,
    inputValue56: false,
  });
 

  const handleHighlightButton = (highlight: boolean) => {
    setHighlightButton(highlight);
  };

  const handleAction = (actionType: ActionType) => {
    if (!selectedProject) {
      console.error("No project selected.");
      return;
    }

    setIsModalOpen(false); // Close the modal after an action is selected

    let message = '';

    switch (actionType) {
      case 'save':
        handleSubmit(); // Call your save function
        message = 'Project saved.';
        break;
      case 'download':
        handleDownloadPDF(); // Call your download function
        message = 'PDF downloaded.';
        break;
      case 'delete':
        const confirmDelete = window.confirm('Are you sure you want to delete this project?');
        if (confirmDelete) {
          deleteProject(); // Call your delete function
          message = 'Project deleted.';
        }
        break;
      case 'upload':
        if (isPremium) {
          handleUploadData(); // Call your upload function
          message = 'Measurements uploaded.';
        } else {
          message = 'You need to be a premium member to use this feature.';
        }
        break;
      case 'updatePrices':
        updateGlobalPrices(); // Call your update prices function
        message = 'Prices updated.';
        break;
        case 'updateSettings':
        handleUpdateSettingsToGlobal(); // Call your update prices function
        message = 'Settings updated.';
        break;
      default:
        break;
    }

    if (message) {
      setStatusMessage(message);
      setIsMessageVisible(true);

      setTimeout(() => {
        setIsMessageVisible(false);
      }, 3000); // Hide the message after 3 seconds
    }
  };
  

  const handleNewMaterialChange = (
    field: keyof UserAddedItem, 
    value: string | number, 
    event?: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event && event.key === 'Enter') {
      // Only add the material when "Enter" key is pressed
      if (newMaterial.materialName && newMaterial.price && newMaterial.quantity) {
        const newItems = [...userAddedItems, newMaterial];
        setUserAddedItems(newItems);
        setNewMaterial({ materialName: '', quantity: 0, price: 0 }); // Reset the new material input fields
        saveFormDataToLocalStorage();
      }
    } else {
      // Update the new material data as the user types
      setNewMaterial((prev) => ({ ...prev, [field]: value }));
    }
  };
  
  const handleUserAddedItemChange = (
    index: number, 
    field: keyof UserAddedItem, 
    value: string | number | boolean
  ) => {
    let updatedItems = [...userAddedItems];
  
    if (field === 'checked' && value === true) {
      // Remove the item immediately if the checkbox is clicked
      updatedItems = updatedItems.filter((_, i) => i !== index);
    } else {
      // Update the field value for the item
      updatedItems[index] = { ...updatedItems[index], [field]: value };
    }
  
    setUserAddedItems(updatedItems);
    saveFormDataToLocalStorage();
  }
  

  const isBackgroundWhite = backgroundColor === 'rgba(245, 245, 245)' || backgroundColor === 'white';

  

  const deleteProject = async () => {
    try {
      setLoadingData(true);
      if (selectedProject) {
        
        
        const response = await axios.delete(`https://projectprice-ad06ee250897.herokuapp.com/form/form/${selectedProject._id}`);
      
  
        if (response.status === 200 || response.status === 204) { // Handle both 200 and 204 status codes
          try {
            const pointsResponse = await axios.delete(`https://projectprice-ad06ee250897.herokuapp.com/points/delete/${selectedProject._id}`);
           
          } catch (pointsError:any) {
            if (pointsError.response && pointsError.response.status === 404) {
              
            } else {
              throw pointsError; // Re-throw if it's a different error
            }
          }
  
          localStorage.removeItem(`formData_${selectedProject._id}`);
  
        setFormData(prevState => ({
          ...prevState,
          inputValue1: 0,
          inputValue2: 0,
          inputValue3: 0,
          inputValue4: 0,
          inputValue5: 0,
          inputValue6: 0,
          inputValue7: 0,
          inputValue8: 0,
          inputValue9: 0,
          inputValue10: 0,
          inputValue11: 0,
          inputValue12: 0,
          inputValue13: 0,
          inputValue14: 0,
          inputValue15: 0,
          inputValue16: 0,
          inputValue17: 0,
          inputValue18: 0,
          inputValue19: 0,
          inputValue20: 0,
          inputValue21: 0,
          inputValue22: 0,
          inputValue23: 0,
          inputValue24: 0,
          inputValue25: 0,
          inputValue26: 0,
          inputValue27: 0,
          inputValue28: 0,
          inputValue29: 0,
          inputValue30: 0,
          inputValue31: 0,
          inputValue32: 0,
          inputValue33: 0,
          inputValue34: 0,
          inputValue35: 0,
          inputValue36: 0,
          inputValue37: 0,
          inputValue38: 0,
          inputValue39: 0,
          inputValue40: 0,
          inputValue41: 0,
          inputValue42: 0,
          inputValue43: 0,
          inputValue44: 0,
          inputValue45: 0,
          inputValue46: 0,
          inputValue47: 0,
          inputValue48: 0,
          inputValue49: 0,
          inputValue50: 0,
          inputValue51: 0,
          inputValue52: 0,
          inputValue53: 0,
          inputValue54: 0,
          inputValue55: 0,
          inputValue56: 0,
          inputValue57: 0,
          inputValue58: 0,
          inputValueSetts: 0,
          projectPrice: projectPrice,
        }));
        
      
        const updatedProjects = projects.filter(project => project._id !== selectedProject._id);
        setProjects(updatedProjects);

        // Clear the selected project
        setSelectedProject(null);

        // Handle any other UI updates or state changes after successful deletion
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } else {
      console.error("No project selected.");
      // Handle error scenarios here
    }
  } catch (error) {
    console.error("Error deleting project:", error);
    // Handle error scenarios here
  } finally {
    setLoadingData(false);
  }
};

  const handleDownloadPDF = () => {
    const pdf = new jsPDF('p', 'pt', 'letter');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const originalToggleState = toggleUnusedValues;

    setToggleUnusedValues(true);

    const addNewMaterialRow = document.getElementById('add-new-material-row');
  if (addNewMaterialRow) {
    addNewMaterialRow.style.display = 'none';
  }

    setTimeout(() => {
    
    if (table1Ref.current && table2Ref.current) {
      html2canvas(table1Ref.current).then((canvas1) => {
        html2canvas(table2Ref.current as HTMLElement).then((canvas2) => {
          const imgData1 = canvas1.toDataURL('image/png');
          const imgData2 = canvas2.toDataURL('image/png');
    
          const scale = 0.5;
          const scaleheight = 0.28;
          const halfScale = 0.5;
          const startYPositionForTable2 = canvas1.height * scaleheight + 100; // Adjust the Y position
          const projectName = selectedProject?.projectName;
          // Add image from Table 1
          pdf.addImage(imgData1, 'PNG', 50, 50, canvas1.width * scale, canvas1.height * scaleheight);
      
          // Check if Table 2 fits on the same page, otherwise add it to a new page
          if (startYPositionForTable2 + (canvas2.height * halfScale) <= pageHeight) {
            // Add image from Table 2 on the same page
            pdf.addImage(imgData2, 'PNG', 50, startYPositionForTable2, canvas2.width * halfScale, canvas2.height * halfScale);
          } else {
            // Calculate aspect ratio
            const aspectRatio = canvas2.width / canvas2.height;
            
            // Calculate new dimensions to fit the page
            const newWidth = pageWidth - 50; // Adjust as needed
            const newHeight = newWidth / aspectRatio;
            
            // Add a new page for Table 2
            pdf.addPage();
            pdf.addImage(imgData2, 'PNG', 50, 50, newWidth * halfScale, newHeight * halfScale);
          }
      
          pdf.save(`${projectName}.pdf`);
        });
      });
    }
    setToggleUnusedValues(originalToggleState);
  }, 0);
  };

  const toggleValueMinimized = () => {
    setIsValueMinimized(!isValueMinimized);
  };

  const toggleLabourMinimized = () => {
    setIsLabourMinimized(!isLabourMinimized);
  };

  const toggleResultMinimized = () => {
    setIsResultMinimized(!isResultMinimized);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
    setManualInput(true);
  };

  const [lockedFields, setLockedFields] = useState<Record<string, boolean>>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
  
    // Only allow change if the field is not locked
    if (!lockedFields[name]) {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setShouldRecalculate(true);
      setChangesMade(true);

    }
  };
  
  // Helper function to automatically lock specific fields
  const autoLockField = (fieldName: string) => {
    const fieldsToLock = [
      'adhesiveCalc', 'totalWasteCalc', 'hardcoreCalc', 'pavingCalc', 'edgingCalc', 
      'blockPavingCalc', 'settsCalc', 'blockPavingScreed', 'kilnSand', 'membraneCalc', 
      'pointingCalc', 'curbCalc', 'sandCalc', 'cementCalc', 'ballastCalc', 
      'buildingSandCalc', 'febCalc', 'asphaltCalc', 'concreteCalc', 'rebarCalc', 
      'postCalc', 'panelCalc', 'postcreteCalc', 'deckingCalc', 'frameworkCalc', 
      'fixingScrewCalc', 'deckingScrewCalc', 'fasciaCalc', 'blockworkCalc', 
      'brickworkCalc', 'claddingCalc', 'tileAdhesiveCalc', 'stoneFacingCalc', 
      'renderCalc', 'channelDrainCalc', 'pipeCalc', 'gullyCalc', 'fittingsCalc', 
      'manholeCoverCalc', 'sleeperCalc', 'gravelCalc', 'barkCalc', 'artiCalc', 
      'turfCalc', 'soilCalc', 'diggerCalc', 'dumperCalc',
    ];
    
    if (fieldsToLock.includes(fieldName)) {
      setLockedFields(prevState => ({
        ...prevState,
        [fieldName]: true,
      }));
    }
  };

  
  const handleLockBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    
    // Introduce a small delay before locking the field
    setTimeout(() => {
      setLockedFields(prevState => ({
        ...prevState,
        [name]: true, // Lock the field after a short delay
      }));
      saveFormDataToLocalStorage();
    }, 100); // 100ms delay to ensure the input value is fully registered
  };
  
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
  
    // Unlock the field when it gains focus
    setLockedFields(prevState => ({
      ...prevState,
      [name]: false,
    }));
  };

const handleLockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, checked } = e.target;
  setLockedFields(prevState => ({
      ...prevState,
      [name]: checked,
  }));
  saveFormDataToLocalStorage()
};

  const timeout = useRef<NodeJS.Timeout | null>(null);

useEffect(() => {
    if (changesMade) {
       
        timeout.current = setTimeout(() => {
           
            setChangesMade(false);
        }, 2000);
    }

    return () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };
}, [changesMade]);

  
  

  const [checkedItems, setCheckedItems] = useState<boolean[]>(Array(52).fill(false));
  
  useEffect(() => {
    if (loadedData && loadedData.checkedItems) {
        const updatedCheckedItems = Array(52).fill(false);
        loadedData.checkedItems.forEach((item: boolean, index: number) => {
          updatedCheckedItems[index] = item;
        });
        setCheckedItems(updatedCheckedItems);
    }
}, [loadedData]);

  

const [formData, setFormData] = useState<FormData>({
  submitted: false,
  ...loadedData,
  inputValue1: loadedData?.inputValue1 || 0,
  inputValue2: loadedData?.inputValue2 || 0,
  inputValue3: loadedData?.inputValue3 || 0,
  inputValue4: loadedData?.inputValue4 || 0,
  inputValue5: loadedData?.inputValue5 || 0,
  inputValue6: loadedData?.inputValue6 || 0,
  inputValue7: loadedData?.inputValue7 || 0,
  inputValue8: loadedData?.inputValue8 || 0,
  inputValue9: loadedData?.inputValue9 || 0,
  inputValue10: loadedData?.inputValue10 || 0,
  inputValue11: loadedData?.inputValue11 || 0,
  inputValue12: loadedData?.inputValue12 || 0,
  inputValue13: loadedData?.inputValue13 || 0,
  inputValue14: loadedData?.inputValue14 || 0,
  inputValue15: loadedData?.inputValue15 || 0,
  inputValue16: loadedData?.inputValue16 || 0,
  inputValue17: loadedData?.inputValue17 || 0,
  inputValue18: loadedData?.inputValue18 || 0,
  inputValue19: loadedData?.inputValue19 || 0,
  inputValue20: loadedData?.inputValue20 || 0,
  inputValue21: loadedData?.inputValue21 || 0,
  inputValue22: loadedData?.inputValue22 || 0,
  inputValue23: loadedData?.inputValue23 || 0,
  inputValue24: loadedData?.inputValue24 || 0,
  inputValue25: loadedData?.inputValue25 || 0,
  inputValue26: loadedData?.inputValue26 || 0,
  inputValue27: loadedData?.inputValue27 || 0,
  inputValue28: loadedData?.inputValue28 || 0,
  inputValue29: loadedData?.inputValue29 || 0,
  inputValue30: loadedData?.inputValue30 || 0,
  inputValue31: loadedData?.inputValue31 || 0,
  inputValue32: loadedData?.inputValue32 || 0,
  inputValue33: loadedData?.inputValue33 || 0,
  inputValue34: loadedData?.inputValue34 || 0,
  inputValue35: loadedData?.inputValue35 || 0,
  inputValue36: loadedData?.inputValue36 || 0,
  inputValue37: loadedData?.inputValue37 || 0,
  inputValue38: loadedData?.inputValue38 || 0,
  inputValue39: loadedData?.inputValue39 || 0,
  inputValue40: loadedData?.inputValue40 || 0,
  inputValue41: loadedData?.inputValue41 || 0,
  inputValue42: loadedData?.inputValue42 || 0,
  inputValue43: loadedData?.inputValue43 || 0,
  inputValue44: loadedData?.inputValue44 || 0,
  inputValue45: loadedData?.inputValue45 || 0,
  inputValue46: loadedData?.inputValue46 || 0,
  inputValue47: loadedData?.inputValue47 || 0,
  inputValue48: loadedData?.inputValue48 || 0,
  inputValue49: loadedData?.inputValue49 || 0,
  inputValue50: loadedData?.inputValue50 || 0,
  inputValue51: loadedData?.inputValue51 || 0,
  inputValue52: loadedData?.inputValue52 || 0,
  inputValue53: loadedData?.inputValue53 || 0,
  inputValue54: loadedData?.inputValue54 || 0,
  inputValue55: loadedData?.inputValue55 || 0,
  inputValue56: loadedData?.inputValue56 || 0,
  inputValue57: loadedData?.inputValue57 || 0,
  inputValue58: loadedData?.inputValue58 || 0,
  inputValueSetts: loadedData?.inputValueSetts || 0,
  pavingWasteCalc: loadedData?.pavingWasteCalc || 0,
  bPavingWasteCalc: loadedData?.bPavingWasteCalc || 0,
  blockPavingCalc: loadedData?.blockPavingCalc || 0,
  pavingHardcoreCalc: loadedData?.pavingHardcoreCalc || 0,
  bPavingHardcoreCalc: loadedData?.bPavingHardcoreCalc || 0,
  postCalc: loadedData?.postCalc || 0,
  sandCalc: loadedData?.sandCalc || 0,
  cementCalc: loadedData?.cementCalc || 0,
  adhesiveCalc: loadedData?.adhesiveCalc || 0,
  panelCalc: loadedData?.panelCalc || 0,
  ballastCalc: loadedData?.ballastCalc || 0,
  footingCementCalc: loadedData?.footingCementCalc || 0,
  wallMetreCalc: loadedData?.wallMetreCalc || 0,
  blockworkCalc: loadedData?.blockworkCalc || 0,
  brickworkCalc: loadedData?.brickworkCalc || 0,
  buildingSandCalc: loadedData?.buildingSandCalc || 0,
  brickSandCalc: loadedData?.brickSandCalc || 0,
  tileAdhesiveCalc: loadedData?.tileAdhesiveCalc || 0,
  sleeperCalc: loadedData?.sleeperCalc || 0,
  postcreteCalc: loadedData?.postcreteCalc || 0,
  soilCalc: loadedData?.soilCalc || 0,
  pavingCalc: loadedData?.pavingCalc || 0,
  gravelCalc: loadedData?.gravelCalc || 0,
  wasteCalc: loadedData?.wasteCalc || 0,
  hardcoreCalc: loadedData?.hardcoreCalc || 0,
  pavingPrice: loadedData?.pavingPrice || 0,
  bPavingPrice: loadedData?.bPavingPrice || 0,
  membraneCalc: loadedData?.membraneCalc || 0,
  labourPrice: loadedData?.labourPrice || 0,
  marginPrice: loadedData?.marginPrice || 0,
  pointingCalc: loadedData?.pointingCalc || 0,
  pavingCementCalc: loadedData?.pavingCementCalc || 0,
  totalWasteCalc: loadedData?.totalWasteCalc || 0,
  footingCalc: loadedData?.footingCalc || 0,
  edgingCalc: loadedData?.edgingCalc || 0,
  claddingCalc: loadedData?.claddingCalc || 0,
  deckingCalc: loadedData?.deckingCalc || 0,
  stoneFacingCalc: loadedData?.stoneFacingCalc || 0,
  renderCalc: loadedData?.renderCalc || 0,
  fasciaCalc: loadedData?.fasciaCalc || 0,
  frameworkCalc: loadedData?.frameworkCalc || 0,
  fixingScrewCalc: loadedData?.fixingScrewCalc || 0,
  deckingScrewCalc: loadedData?.deckingScrewCalc || 0,
  concreteCalc: loadedData?.concreteCalc || 0,
  pavingBedCalc: loadedData?.pavingBedCalc || 0,
  concreteDepth: loadedData?.concreteDepth || 0,
  concretePadCalc: loadedData?.concretePadCalc || 0,
  curbCalc: loadedData?.curbCalc || 0,
  rebarCalc: loadedData?.rebarCalc || 0,
  pavingDepth: loadedData?.pavingDepth || 0,
  blockPavingScreed: loadedData?.blockPavingScreed || 0,
  barkCalc: loadedData?.barkCalc || 0,
  bPavingDepth: loadedData?.bPavingDepth || 0,
  totalCost2: loadedData?.totalCost2 || 0,
  pavingHardcore: loadedData?.pavingHardcore || 0,
  bPavingHardcore: loadedData?.bPavingHardcore || 0,
  buildingSandCement: loadedData?.buildingSandCement || 0,
  pavingScreedCalc: loadedData?.pavingScreedCalc || 0,
  artificialTurfScreed: loadedData?.artificialTurfScreed || 0,
  settsPointingCalc: loadedData?.settsPointingCalc || 0,
  kilnSand: loadedData?.kilnSand || 0,
  pavingPointing: loadedData?.pavingPointing || 0,
  settsCementBags: loadedData?.settsCementBags || 0,
  pavingCementBags: loadedData?.pavingCementBags || 0,
  settsWaste: loadedData?.settsWaste || 0,
  edgingWasteCalc: loadedData?.edgingWasteCalc || 0,
  settsHardcore: loadedData?.settsHardcore || 0,
  edgingHardcore: loadedData?.edgingHardcore || 0,
  concretePadHardcore: loadedData?.concretePadHardcore || 0,
  asphaltHardcore: loadedData?.asphaltHardcore || 0,
  asphaltWaste: loadedData?.asphaltWaste || 0,
  dumperCalc: loadedData?.dumperCalc || 0,
  diggerCalc: loadedData?.diggerCalc || 0,
  turfCalc: loadedData?.turfCalc || 0,
}as FormData);

const fetchProjectData = async (projectId: string) => {
  
  try {
    setLoadingData(true);
    const response = await axios.get(`https://projectprice-ad06ee250897.herokuapp.com/form/form/${projectId}`);
    const loadedData = response.data.data;

    // Extract and merge dynamic fields
    const dynamicFields = loadedData.dynamicFields || {};
    const mergedData = { ...loadedData, ...dynamicFields };

    // Set the merged data to your state
    setLoadedData(mergedData);

    setCheckedItems(mergedData.checkedItems || new Array(52).fill(false));
    setLockedFields(mergedData.lockedFields || {});
    setUserAddedItems(mergedData.userAddedItems || []);
    setSelectedMaterial(mergedData.selectedFenceMaterial || '');
    setSettingsData(mergedData.settingsData || {});

    // Check and set material data
    if (mergedData.materialPrices && Object.keys(mergedData.materialPrices).length > 0) {
      setMaterialData(mergedData.materialPrices); // Load project-specific prices
    } else {
      // If no project-specific prices, fallback to global prices
      await loadMaterialPrices({ materialPrices: {} });
    }

    // Set form data
    setFormData({
      ...formData,
      ...mergedData,
      ...settingsData,
      inputValue1: mergedData?.inputValue1 || 0,
      inputValue2: mergedData?.inputValue2 || 0,
      inputValue3: mergedData?.inputValue3 || 0,
      inputValue4: mergedData?.inputValue4 || 0,
      inputValue5: mergedData?.inputValue5 || 0,
      inputValue6: mergedData?.inputValue6 || 0,
      inputValue7: mergedData?.inputValue7 || 0,
      inputValue8: mergedData?.inputValue8 || 0,
      inputValue9: mergedData?.inputValue9 || 0,
      inputValue10: mergedData?.inputValue10 || 0,
      inputValue11: mergedData?.inputValue11 || 0,
      inputValue12: mergedData?.inputValue12 || 0,
      inputValue13: mergedData?.inputValue13 || 0,
      inputValue14: mergedData?.inputValue14 || 0,
      inputValue15: mergedData?.inputValue15 || 0,
      inputValue16: mergedData?.inputValue16 || 0,
      inputValue17: mergedData?.inputValue17 || 0,
      inputValue18: mergedData?.inputValue18 || 0,
      inputValue19: mergedData?.inputValue19 || 0,
      inputValue20: mergedData?.inputValue20 || 0,
      inputValue21: mergedData?.inputValue21 || 0,
      inputValue22: mergedData?.inputValue22 || 0,
      inputValue23: mergedData?.inputValue23 || 0,
      inputValue24: mergedData?.inputValue24 || 0,
      inputValue25: mergedData?.inputValue25 || 0,
      inputValue26: mergedData?.inputValue26 || 0,
      inputValue27: mergedData?.inputValue27 || 0,
      inputValue28: mergedData?.inputValue28 || 0,
      inputValue29: mergedData?.inputValue29 || 0,
      inputValue30: mergedData?.inputValue30 || 0,
      inputValue31: mergedData?.inputValue31 || 0,
      inputValue32: mergedData?.inputValue32 || 0,
      inputValue33: mergedData?.inputValue33 || 0,
      inputValue34: mergedData?.inputValue34 || 0,
      inputValue35: mergedData?.inputValue35 || 0,
      inputValue36: mergedData?.inputValue36 || 0,
      inputValue37: mergedData?.inputValue37 || 0,
      inputValue38: mergedData?.inputValue38 || 0,
      inputValue39: mergedData?.inputValue39 || 0,
      inputValue40: mergedData?.inputValue40 || 0,
      inputValue41: mergedData?.inputValue41 || 0,
      inputValue42: mergedData?.inputValue42 || 0,
      inputValue43: mergedData?.inputValue43 || 0,
      inputValue44: mergedData?.inputValue44 || 0,
      inputValue45: mergedData?.inputValue45 || 0,
      inputValue46: mergedData?.inputValue46 || 0,
      inputValue47: mergedData?.inputValue47 || 0,
      inputValue48: mergedData?.inputValue48 || 0,
      inputValue49: mergedData?.inputValue49 || 0,
      inputValue50: mergedData?.inputValue50 || 0,
      inputValue51: mergedData?.inputValue51 || 0,
      inputValue52: mergedData?.inputValue52 || 0,
      inputValue53: mergedData?.inputValue53 || 0,
      inputValue54: mergedData?.inputValue54 || 0,
      inputValue55: mergedData?.inputValue55 || 0,
      inputValue56: mergedData?.inputValue56 || 0,
      inputValue57: mergedData?.inputValue57 || 0,
      inputValue58: mergedData?.inputValue58 || 0,
      inputValueSetts: mergedData?.inputValueSetts || 0,
      pavingWasteCalc: mergedData?.pavingWasteCalc || 0,
      bPavingWasteCalc: mergedData?.bPavingWasteCalc || 0,
      blockPavingCalc: mergedData?.blockPavingCalc || 0,
      pavingHardcoreCalc: mergedData?.pavingHardcoreCalc || 0,
      bPavingHardcoreCalc: mergedData?.bPavingHardcoreCalc || 0,
      postCalc: mergedData?.postCalc || 0,
      sandCalc: mergedData?.sandCalc || 0,
      cementCalc: mergedData?.cementCalc || 0,
      adhesiveCalc: mergedData?.adhesiveCalc || 0,
      panelCalc: mergedData?.panelCalc || 0,
      ballastCalc: mergedData?.ballastCalc || 0,
      footingCementCalc: mergedData?.footingCementCalc || 0,
      wallMetreCalc: mergedData?.wallMetreCalc || 0,
      blockworkCalc: mergedData?.blockworkCalc || 0,
      brickworkCalc: mergedData?.brickworkCalc || 0,
      buildingSandCalc: mergedData?.buildingSandCalc || 0,
      brickSandCalc: mergedData?.brickSandCalc || 0,
      tileAdhesiveCalc: mergedData?.tileAdhesiveCalc || 0,
      sleeperCalc: mergedData?.sleeperCalc || 0,
      postcreteCalc: mergedData?.postcreteCalc || 0,
      soilCalc: mergedData?.soilCalc || 0,
      pavingCalc: mergedData?.pavingCalc || 0,
      gravelCalc: mergedData?.gravelCalc || 0,
      wasteCalc: mergedData?.wasteCalc || 0,
      hardcoreCalc: mergedData?.hardcoreCalc || 0,
      pavingPrice: mergedData?.pavingPrice || 0,
      bPavingPrice: mergedData?.bPavingPrice || 0,
      membraneCalc: mergedData?.membraneCalc || 0,
      labourPrice: mergedData?.labourPrice || 0,
      marginPrice: mergedData?.marginPrice || 0,
      pointingCalc: mergedData?.pointingCalc || 0,
      pavingCementCalc: mergedData?.pavingCementCalc || 0,
      totalWasteCalc: mergedData?.totalWasteCalc || 0,
      footingCalc: mergedData?.footingCalc || 0,
      edgingCalc: mergedData?.edgingCalc || 0,
      claddingCalc: mergedData?.claddingCalc || 0,
      deckingCalc: mergedData?.deckingCalc || 0,
      stoneFacingCalc: mergedData?.stoneFacingCalc || 0,
      renderCalc: mergedData?.renderCalc || 0,
      fasciaCalc: mergedData?.fasciaCalc || 0,
      frameworkCalc: mergedData?.frameworkCalc || 0,
      fixingScrewCalc: mergedData?.fixingScrewCalc || 0,
      deckingScrewCalc: mergedData?.deckingScrewCalc || 0,
      concreteCalc: mergedData?.concreteCalc || 0,
      pavingBedCalc: mergedData?.pavingBedCalc || 0,
      concreteDepth: mergedData?.concreteDepth || 0,
      concretePadCalc: mergedData?.concretePadCalc || 0,
      curbCalc: mergedData?.curbCalc || 0,
      rebarCalc: mergedData?.rebarCalc || 0,
      pavingDepth: mergedData?.pavingDepth || 0,
      projectPrice: mergedData?.projectPrice || 0,
      blockPavingScreed: mergedData?.blockPavingScreed || 0,
      barkCalc: mergedData?.barkCalc || 0,
      bPavingDepth: mergedData?.bpavingDepth || 0,
      pavingHardcore: mergedData?.pavingHardcore || 0,
      bPavingHardcore: mergedData?.bPavingHardcore || 0,
      totalCost2: mergedData?.totalCost2 || 0,
      buildingSandCement: mergedData?.buildingSandCement || 0,
      pavingScreedCalc: mergedData?.pavingScreedCalc || 0,
      artificialTurfScreed: mergedData?.artificialTurfScreed || 0,
      settsPointingCalc: mergedData?.settsPointingCalc || 0,
      kilnSand: mergedData?.kilnSand || 0,
      pavingPointing: mergedData?.pavingPointing || 0,
      settsCementBags: mergedData?.settsCementBags || 0,
      pavingCementBags: mergedData?.pavingCementBags || 0,
      settsWaste: mergedData?.settsWaste || 0,
      edgingWasteCalc: mergedData?.edgingWasteCalc || 0,
      settsHardcore: mergedData?.settsHardcore || 0,
      edgingHardcore: mergedData?.edgingHardcore || 0,
      concretePadHardcore: mergedData?.concretePadHardcore || 0,
      asphaltHardcore: mergedData?.asphaltHardcore || 0,
      asphaltWaste: mergedData?.asphaltWaste || 0,
      artiCalc: mergedData?.artiCalc || 0,
      turfCalc: mergedData?.turfCalc || 0,
      diggerCalc: mergedData?.diggerCalc || 0,
      dumperCalc: mergedData?.dumperCalc || 0,
      channelDrainCalc: mergedData?.channelDrainCalc || 0,
      pipeCalc: mergedData?.pipeCalc || 0,
      gullyCalc: mergedData?.gullyCalc || 0,
      fittingsCalc: mergedData?.fittingsCalc || 0,
      manholeCoverCalc: mergedData?.manholeCoverCalc || 0,
      febCalc: mergedData?.febCalc || 0,
    });
  } catch (error) {
    console.error("Error fetching project data:", error);
  } finally {
    setLoadingData(false);
  }
};


const fetchData = async (projectId?: string) => {
  try {
      setLoadingData(true);

      // Fetch the list of projects
      const projectsResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/form/form');
      const projectsData = projectsResponse.data.data;
      setProjects(projectsData);

      const lastUsedProjectName = localStorage.getItem('lastUsedProject');
      let storedData: any = null; // Define storedData in the correct scope

      if (lastUsedProjectName) {
          const selectedProject = projectsData.find((project: any) => project.projectName === lastUsedProjectName);
          if (selectedProject) {
              
              setSelectedProject(selectedProject);

              const storedDataString = localStorage.getItem(`formData_${selectedProject._id}`);
                
              if (storedDataString) {
                  storedData = JSON.parse(storedDataString);
              }

              if (storedData) {
                 
                  setFormData(storedData);
                  setCheckedItems(storedData.checkedItems || []);
                  setLockedFields(storedData.lockedFields || {});
                  setUserAddedItems(storedData.userAddedItems || []);
                  setSelectedMaterial(storedData.selectedFenceMaterial || '');
                  
                  if (storedData.settingsData) {
                    setSettingsData(storedData.settingsData);
                  } else {
                    await loadGlobalSettingsData();
                  }
              } else {
                  await fetchProjectData(selectedProject._id);
              }

              // Handle material prices using loadMaterialPrices
              await loadMaterialPrices(selectedProject);
          }
      }

  } catch (error) {
      console.error('Error fetching data:', error);
  } finally {
      setLoadingData(false);
  }
};


const loadGlobalSettingsData = async () => {
  try {

    const settingsResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/settingscontroller/settingscontroller');
    const globalSettingsData: SettingsData = settingsResponse.data.data;
   
    setSettingsData(globalSettingsData);
    setIsSettingsDataError(false);
  } catch (error) {
    setIsSettingsDataError(true);
    console.error('Error fetching global settings data:', error);
  }
};
  
  const loadMaterialPrices = async (selectedProject: any) => {
   
    if (selectedProject.materialPrices && Object.keys(selectedProject.materialPrices).length > 0) {
      setMaterialData(selectedProject.materialPrices); // Use the project-specific prices
    
   
    } else {
      // If no specific prices are stored with the project, fetch the global prices
      try {
        const materialResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/pricelist/pricelist');
        const materialData: PriceData = materialResponse.data.data;
        setMaterialData(materialData);
        setIsMaterialDataError(false);
      } catch (error) {
        setIsMaterialDataError(true);
        console.error('Error fetching material data:', error);
      }
    }
  };
  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const updateGlobalPrices = async () => {
    if (!selectedProject) {
        console.error("No project selected. Cannot update prices.");
        return;
    }

    if (window.confirm("Are you sure you want to update and set the material prices from the Pricing tab?")) {
        setIsUpdating(true);
        try {
            // Fetch the latest global material prices
            const materialResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/pricelist/pricelist');
            const materialPricesData = materialResponse.data.data;

            // Define the type for sanitizedMaterialPrices
            let sanitizedMaterialPrices: { [key: string]: number } = {};

            if (Array.isArray(materialPricesData)) {
                // If it's an array, format it accordingly
                sanitizedMaterialPrices = formatMaterialPrices(materialPricesData);
            } else if (typeof materialPricesData === 'object') {
                // If it's an object, use its values directly
                const { prices, ...priceObject } = materialPricesData;

                // Use the prices array if available
                if (Array.isArray(prices)) {
                    prices.forEach(priceItem => {
                        if (priceItem.materialName && typeof priceItem.price === 'number') {
                            sanitizedMaterialPrices[priceItem.materialName] = priceItem.price;
                        }
                    });
                }

                // Merge with any other price data in the object
                Object.keys(priceObject).forEach(key => {
                    if (!['userId', '_id', '__v'].includes(key) && typeof priceObject[key] === 'number') {
                        sanitizedMaterialPrices[key] = priceObject[key];
                    }
                });
            } else {
                console.error("Unexpected material prices format:", materialPricesData);
                return;
            }

            // Update the project's material prices with the sanitized global prices
            await axios.put(`https://projectprice-ad06ee250897.herokuapp.com/form/form/${selectedProject._id}`, {
                ...selectedProject,
                materialPrices: sanitizedMaterialPrices,
            });

            // Set the material data in the local state to reflect the change
            setMaterialData(sanitizedMaterialPrices);
            setShouldRecalculate(true);

            // Re-fetch the project data to ensure it's updated
            const updatedProjectResponse = await axios.get(`https://projectprice-ad06ee250897.herokuapp.com/form/form/${selectedProject._id}`);
            const updatedProject = updatedProjectResponse.data.data;

            // Update the entire project state, including material prices
            setSelectedProject(updatedProject);
            setMaterialData(updatedProject.materialPrices);
        } catch (error) {
            console.error('Error updating material prices:', error);
            alert('Failed to update material prices.');
        } finally {
            setIsUpdating(false);
        }
    }
};

const handleUpdateSettingsToGlobal = async () => {
  if (!selectedProject) {
    console.error('No project selected. Cannot update settings.');
    return;
  }

  if (window.confirm('Are you sure you want to update the project settings to the global settings?')) {
    setIsUpdating(true);
    try {
      // Fetch the global settings
      const settingsResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/settingscontroller/settingscontroller');
      const globalSettingsData: SettingsData = settingsResponse.data.data;

      if (!globalSettingsData) {
        throw new Error("Global settings data is undefined.");
      }

      // Update the project's settings with the global settings
      await axios.put(`https://projectprice-ad06ee250897.herokuapp.com/form/form/${selectedProject._id}`, {
        ...selectedProject,
        settingsData: globalSettingsData,
      });

      // Re-fetch the updated project to confirm changes
      const updatedProjectResponse = await axios.get(`https://projectprice-ad06ee250897.herokuapp.com/form/form/${selectedProject._id}`);
      const updatedProject = updatedProjectResponse.data.data;

      if (!updatedProject) {
        throw new Error("Updated project data is undefined.");
      }

      // Ensure the updated settings are applied
      const updatedSettingsData = updatedProject.settingsData || updatedProject.dynamicFields?.settingsData;
      if (!updatedSettingsData) {
        throw new Error("Updated project settings data is undefined.");
      }

      // Update the state with the new settings data
      setSelectedProject(updatedProject);
      setSettingsData(updatedSettingsData);
      setShouldRecalculate(true);

      // Update the formData state with the new settings data
      setFormData((prevFormData) => ({
        ...prevFormData,
        settingsData: updatedSettingsData,
      }));

      // Save the updated project data and settings to local storage
      localStorage.setItem(`formData_${updatedProject._id}`, JSON.stringify({
        ...updatedProject,
        settingsData: updatedSettingsData,
      }));

    } catch (error) {
      console.error('Error updating project settings:', error);
      alert('Failed to update project settings.');
    } finally {
      setIsUpdating(false);
    }
  }
};




const fetchDataAndUpdateForm = async () => {
  if (!selectedProject) {
    console.error('No project selected.');
    return false;
  }
  const projectId = selectedProject._id;
  if (!projectId) {
    console.error('Project ID missing.');
    return false;
  }

  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`https://projectprice-ad06ee250897.herokuapp.com/points/load/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.data || !response.data.data) {
  
      return false;
    }

    const projectData = response.data.data;

    if (!projectData || !projectData.area) {
      console.error('No area data found.');
      return false;
    }

    // Sum up the relevant area data
    const totalPavingArea = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('patio') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);

    const totalEdgingPerimeter = Object.keys(projectData.perimeter).reduce((total, key) => {
      if (key.startsWith('edgings') && projectData.perimeter[key]) {
        return total + projectData.perimeter[key];
      }
      return total;
    }, 0);

    const totalChanneldrain = Object.keys(projectData.perimeter).reduce((total, key) => {
      if (key.startsWith('channeldrain') && projectData.perimeter[key]) {
        return total + projectData.perimeter[key];
      }
      return total;
    }, 0);

    const totalBlockPaving = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('blockpaving') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);

    const totalSetts = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('setts') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);

    const totalFencing = Object.keys(projectData.perimeter).reduce((total, key) => {
      if (key.startsWith('fencing') && projectData.perimeter[key]) {
        return total + projectData.perimeter[key];
      }
      return total;
    }, 0);

    const totalWalling = Object.keys(projectData.perimeter).reduce((total, key) => {
      if (key.startsWith('walling') && projectData.perimeter[key]) {
        return total + projectData.perimeter[key];
      }
      return total;
    }, 0);

    const totalConcretePad = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('concretepad') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);

    const totalSleepers = Object.keys(projectData.perimeter).reduce((total, key) => {
      if (key.startsWith('sleepers') && projectData.perimeter[key]) {
        return total + projectData.perimeter[key];
      }
      return total;
    }, 0);

    const totalDrainagePipe = Object.keys(projectData.perimeter).reduce((total, key) => {
      if (key.startsWith('drainagepipe') && projectData.perimeter[key]) {
        return total + projectData.perimeter[key];
      }
      return total;
    }, 0);

    const totalCurbLength = Object.keys(projectData.perimeter).reduce((total, key) => {
      if (key.startsWith('curb') && projectData.perimeter[key]) {
        return total + projectData.perimeter[key];
      }
      return total;
    }, 0);

    const totalGravel = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('gravel') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);

    const totalArtificialTurf = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('artificialturf') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);

    const totalAsphaltArea = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('asphalt') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);


    const totalTurf = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('turf') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);

    const totalBark = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('bark') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);

    const manholeCoverCount = projectData.manholeCoverCount || 0;
    const gullyPotCount = projectData.gullyPotCount || 0;
    const curbCount = projectData.curbCount || 0;
    const deckingDimensions = projectData.deckingDimensions || {};

    const totalDeckingWidth = Object.keys(deckingDimensions).reduce((total, key) => {
      if (key.startsWith('decking') && deckingDimensions[key]?.width) {
        return total + deckingDimensions[key].width;
      }
      return total;
    }, 0);

    const totalDeckingLength = Object.keys(deckingDimensions).reduce((total, key) => {
      if (key.startsWith('decking') && deckingDimensions[key]?.length) {
        return total + deckingDimensions[key].length;
      }
      return total;
    }, 0);

    const totalDeckingArea = Object.keys(projectData.area).reduce((total, key) => {
      if (key.startsWith('decking') && projectData.area[key]) {
        return total + projectData.area[key];
      }
      return total;
    }, 0);

    // Update the form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      inputValue1: Math.ceil(totalPavingArea),
      inputValue2: Math.ceil(totalBlockPaving),
      inputValue3: Math.ceil(totalEdgingPerimeter),
      inputValueSetts: Math.ceil(totalSetts),
      inputValue5: Math.ceil(totalFencing),
      inputValue7: Math.ceil(totalWalling),
      inputValue9: Math.ceil(totalChanneldrain),
      inputValue11: Number(gullyPotCount.toFixed(0)),
      inputValue47: Math.ceil(totalConcretePad),
      inputValue17: Math.ceil(totalSleepers),
      inputValue18: Math.ceil(totalGravel),
      inputValue51: Math.ceil(totalArtificialTurf),
      inputValue19: Math.ceil(totalTurf),
      inputValue50: Math.ceil(totalBark),
      inputValue6: Number(totalDeckingWidth.toFixed(1)),
      inputValue46: Number(totalDeckingLength.toFixed(1)),
      inputValue55: Number(totalDeckingArea.toFixed(1)),
      inputValue13: Number(manholeCoverCount.toFixed(0)),
      inputValue10: Number(totalDrainagePipe.toFixed(2)),
      inputValue48: Math.ceil(curbCount),
      inputValue49: Number(totalCurbLength.toFixed(2)),
      inputValue56: Number(totalAsphaltArea.toFixed(2)),
    }));

    // Update the updatedFields state
    setUpdatedFields({
      inputValue1: totalPavingArea > 0,
      inputValue2: totalBlockPaving > 0,
      inputValue3: totalEdgingPerimeter > 0,
      inputValueSetts: totalSetts > 0,
      inputValue5: totalFencing > 0,
      inputValue7: totalWalling > 0,
      inputValue9: totalChanneldrain > 0,
      inputValue47: totalConcretePad > 0,
      inputValue17: totalSleepers > 0,
      inputValue18: totalGravel > 0,
      inputValue51: totalArtificialTurf > 0,
      inputValue19: totalTurf > 0,
      inputValue50: totalBark > 0,
      inputValue6: totalDeckingWidth > 0,
      inputValue46: totalDeckingLength > 0,
      inputValue55: totalDeckingArea > 0,
      inputValue13: manholeCoverCount > 0,
      inputValue11: gullyPotCount > 0,
      inputValue10: totalDrainagePipe > 0,
      inputValue48: curbCount > 0,
      inputValue49: totalCurbLength > 0,
      inputValue56: totalAsphaltArea > 0,
    });

    setShouldRecalculate(true);
    return true;
  } catch (error) {
    console.error('Error fetching project data:', error);
    return false;
  }
};

const resetUpdatedFields = () => {
  setUpdatedFields({
    inputValue1: false,
    inputValue2: false,
    inputValue3: false,
    inputValueSetts: false,
    inputValue5: false,
    inputValue7: false,
    inputValue9: false,
    inputValue47: false,
    inputValue17: false,
    inputValue18: false,
    inputValue51: false,
    inputValue19: false,
    inputValue50: false,
    inputValue6: false,
    inputValue46: false,
    inputValue55: false,
    inputValue13: false,
    inputValue11: false,
    inputValue10: false,
    inputValue48: false,
    inputValue49: false,
    inputValue56: false,
  });
};

const handleUploadData = async () => {
  const dataUploaded = await fetchDataAndUpdateForm();

  if (dataUploaded) {
    setUploadStatusMessage('Uploaded!');
  } else {
    setUploadStatusMessage('No data, please save design');
  }
  setUploadAttempted(true);

  setTimeout(() => {
    setUploadStatusMessage('');
    setUploadAttempted(false);
  }, 2500); // Clear the message after 2 seconds
};

const handleProjectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
  const projectId = e.target.value;
  const selectedProject = projects.find((project) => project._id === projectId);

  if (selectedProject) {
    await handleSubmit(); // Save the current project's data to the database
    localStorage.removeItem(`formData_${selectedProject._id}`); // Remove the old project's data from localStorage
  }

  if (!selectedProject) {
    console.warn("Selected project not found for ID:", projectId);
    return;
  }

  let loadedData: FormData | null = null;
  localStorage.removeItem('selectedFenceMaterial');

  // Try to get data from local storage first
  const storedDataString = localStorage.getItem(`formData_${selectedProject._id}`);
  if (storedDataString) {
    try {
      loadedData = JSON.parse(storedDataString) as FormData;
    } catch (error) {
      console.error('Error parsing localStorage data:', error);
    }
  }

  // If no local data, get data from the database
  if (!loadedData) {
    try {
      const response = await axios.get(`https://projectprice-ad06ee250897.herokuapp.com/form/form/${projectId}`);
      loadedData = response.data.data as FormData;
    } catch (error) {
      console.error('Error fetching data from API:', error);
    }
  }

  // Fetch global material prices if none are present
  if (loadedData && (!loadedData.materialPrices || Object.keys(loadedData.materialPrices).length === 0)) {
    try {
      const materialResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/pricelist/pricelist');
      const globalMaterialPrices: PriceData = materialResponse.data.data;
      loadedData.materialPrices = globalMaterialPrices;
    } catch (error) {
      console.error('Error fetching global material prices:', error);
    }
  }

  // Safely update states with loaded data
  setSelectedProject(selectedProject);
  setProjectName(selectedProject.projectName);
  setFormData(loadedData as FormData);

  if (loadedData?.materialPrices) {
    setMaterialData(loadedData.materialPrices); // Set project-specific material prices
  }
  if (loadedData?.checkedItems) {
    setCheckedItems(loadedData.checkedItems);
  }
  if (loadedData?.lockedFields) {
    setLockedFields(loadedData.lockedFields);
  }
  if (loadedData?.userAddedItems) {
    setUserAddedItems(loadedData.userAddedItems);
  }
  if (loadedData?.selectedFenceMaterial) {
    setSelectedMaterial(loadedData.selectedFenceMaterial);
  }
  if (loadedData?.settingsData) {
    // Safely access and set settingsData, ensuring fencingMaterials is present
    setSettingsData(loadedData.settingsData || {});
  }

  localStorage.setItem('lastUsedProject', selectedProject.projectName);
  setChangesMade(false);
  setShouldRecalculate(true);
  resetUpdatedFields();
};




  const fetchProjects = async () => {
    try {
      setLoadingData(true);
      const response = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/form/form');
      const projectsData = response.data.data; 
      setProjects(projectsData);
      setLoadingData(false);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (changesMade) {
      saveFormDataToLocalStorage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, checkedItems]);


const formDataRef = useRef(formData);
const checkedItemsRef = useRef(checkedItems);
const lockedFieldsRef = useRef(lockedFields);
const userAddedItemsRef = useRef(userAddedItems);



useEffect(() => {

    formDataRef.current = formData;
    checkedItemsRef.current = checkedItems;
    lockedFieldsRef.current = lockedFields;
    userAddedItemsRef.current = userAddedItems;
}, [formData, checkedItems, lockedFields, userAddedItems]);

const saveFormDataToLocalStorage = _.debounce(() => {
  try {
    if (selectedProject) {
  
     

      // Merge the calculated quantities into formDataRef.current
      const updatedFormData = {
        ...formDataRef.current,
      // Include the calculated data
      };

      // Prepare the data to save, including all necessary fields
      const dataToSave = {
        ...updatedFormData, // Updated form data with calculations
        projectPrice,
        checkedItems: checkedItemsRef.current,
        lockedFields: lockedFieldsRef.current,
        userAddedItems: userAddedItemsRef.current,
        materialPrices: materialData,
        selectedFenceMaterial: selectedMaterial,
      };

      // Store data to local storage
      localStorage.setItem(`formData_${selectedProject._id}`, JSON.stringify(dataToSave));

    }
  } catch (error) {
    console.error("Error saving data:", error);
  }
}, 500);


  const materialOptions = [
    { label: 'Block', value: 'block' },
    { label: 'Brick', value: 'brick' },
    { label: 'Cladding', value: 'cladding' },
    { label: 'Stone facing', value: 'stone' },
    { label: 'Render', value: 'render' },
  ];

  const [selectedMaterial, setSelectedMaterial] = useState<string | null>(null);
  const [selectedWallMaterial, setSelectedWallMaterial] = useState('');

  const selectedFencingMaterial = settingsData.fencingMaterials?.find(
    (material) => material.name === selectedMaterial
  );
  
  const handleFenceMaterialChange = (materialName: string) => {
    setSelectedMaterial(materialName);

    setFormData(prevFormData => ({
      ...prevFormData,
      selectedFenceMaterial: materialName,
    }));
  
    if (materialName) {
      // If a material is selected, save it in localStorage
      localStorage.setItem('selectedFenceMaterial', materialName);
      saveFormDataToLocalStorage();
      // Automatically check the checkbox for "Panels" when a fencing material is selected
      setCheckedItems((prevCheckedItems) => {
        const updatedCheckedItems = [...prevCheckedItems];
        updatedCheckedItems[17] = true; // Assuming 17 is the index for "Panels"
        return updatedCheckedItems;
      });
    } else {
      // If no material is selected (unselect), remove from localStorage
      localStorage.removeItem('selectedFenceMaterial');
  
      // Uncheck the checkbox for "Panels"
      setCheckedItems((prevCheckedItems) => {
        const updatedCheckedItems = [...prevCheckedItems];
        updatedCheckedItems[17] = false; // Uncheck the "Panels"
        return updatedCheckedItems;
      });
    }
  };
  
  
  
  const handleMaterialChange = (option:any) => {
    setSelectedWallMaterial(option);
    // Create a copy of the current checkedItems array
    const newCheckedItems = [...checkedItems];
  
    // Check/uncheck checkboxes based on the selected material
    switch (option) {
      case "block":
        newCheckedItems[24] = false;
        newCheckedItems[25] = true;
        newCheckedItems[26] = true;
        newCheckedItems[27] = true;
        newCheckedItems[28] = true;
        newCheckedItems[29] = true;
        break;
      case "brick":
        newCheckedItems[24] = true;
        newCheckedItems[25] = false;
        newCheckedItems[26] = true;
        newCheckedItems[27] = true;
        newCheckedItems[28] = true;
        newCheckedItems[29] = true;
        break;
      case "cladding":
        newCheckedItems[24] = false;
        newCheckedItems[25] = true;
        newCheckedItems[26] = false;
        newCheckedItems[27] = false;
        newCheckedItems[28] = true;
        newCheckedItems[29] = true;
        break;
        case "stone":
        newCheckedItems[24] = true;
        newCheckedItems[25] = true;
        newCheckedItems[26] = true;
        newCheckedItems[27] = true;
        newCheckedItems[28] = false;
        newCheckedItems[29] = true;
        break;
        case "render":
        newCheckedItems[24] = false;
        newCheckedItems[25] = true;
        newCheckedItems[26] = true;
        newCheckedItems[27] = true;
        newCheckedItems[28] = true;
        newCheckedItems[29] = false;
        break;
      default:
        newCheckedItems[24] = false;
        newCheckedItems[25] = false;
        newCheckedItems[26] = false;
        newCheckedItems[27] = false;
        newCheckedItems[28] = false;
        newCheckedItems[29] = false;
        break;
    }
  
    setCheckedItems(newCheckedItems);
    saveFormDataToLocalStorage();
  };

  useEffect(() => {
    const saveInterval = setInterval(() => {
        handleSubmit();
    }, 120000); // 120000 ms = 2 minutes

    return () => clearInterval(saveInterval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
}, [selectedProject]); // Ensure this is kept up-to-date


const handleSubmit = async (e?: React.FormEvent) => {
  if (e) {
      e.preventDefault();
  }


  if (!selectedProject) {
      return;
  }

  setSaveStatus("Auto saving...");

  const dataToSend = {
    ...formDataRef.current,
    materialPrices: Object.fromEntries(
      Object.entries(materialData).map(([key, value]) => [key, Number(value)])
    ),
    projectPrice,
    lockedFields: lockedFieldsRef.current,
    userAddedItems: userAddedItemsRef.current,
    checkedItems,
    selectedFenceMaterial: selectedMaterial,
  };
  



  try {
      const response = await axios.put(
          `https://projectprice-ad06ee250897.herokuapp.com/form/form/${selectedProject._id}`,
          dataToSend,
      );

      setSaveStatus("Auto saving...");

      setSelectedProject(selectedProject);
  } catch (error: any) {
      console.error("Error submitting form:", error.response?.data);
  }

  setIsCopied(true);

  // Clear only the specific form data from local storage, not the selected project name
  localStorage.removeItem(`formData_${selectedProject._id}`);
  localStorage.removeItem(`selectedFenceMaterial`);
  

  setTimeout(() => {
      setIsCopied(false);
      setSaveStatus("");  // Clear the status message after a delay
  }, 2000);
};


  const initialCheckedItems = new Array(53).fill(false);

  interface MaterialPrices {
    [key: string]: number;
  }

  interface MaterialPrice {
    materialName: string;
    price: number;
  }
  
  const formatMaterialPrices = (pricesArray: MaterialPrice[]): { [key: string]: number } => {
    const formattedPrices: { [key: string]: number } = {};
    
    pricesArray.forEach(({ materialName, price }) => {
      formattedPrices[materialName] = price;
    });
  
    return formattedPrices;
  };
  
  const createProject = async () => {
    try {
      if (!projectNameInput) {
        console.error('Project name is required');
        return;
      }
  
      // Fetch the current project names
      const responseProjects = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/form/form');
      const projectNames = responseProjects.data.data.map((project: Project) => project.projectName);
  
      let newProjectName = projectNameInput;
      let counter = 1;
  
      // Check for duplicate project names and append a suffix until a unique name is found
      while (projectNames.includes(newProjectName)) {
        newProjectName = `${projectNameInput} (${counter})`;
        counter++;
      }
  
      // Fetch global settings
      const settingsResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/settingscontroller/settingscontroller');
      const globalSettingsData: SettingsData = settingsResponse.data.data;
  
      if (!globalSettingsData) {
        throw new Error("Global settings data is undefined.");
      }
  
      // Fetch the current material prices
      const responsePrices = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/pricelist/pricelist');
      const materialPricesData = responsePrices.data.data;
  
      // Handle different formats
      let sanitizedMaterialPrices: MaterialPrices = {};
  
      if (Array.isArray(materialPricesData)) {
        sanitizedMaterialPrices = formatMaterialPrices(materialPricesData);
      } else if (typeof materialPricesData === 'object') {
        const { prices, ...priceObject } = materialPricesData;
  
        if (Array.isArray(prices)) {
          prices.forEach(priceItem => {
            if (priceItem.materialName && typeof priceItem.price === 'number') {
              sanitizedMaterialPrices[priceItem.materialName] = priceItem.price;
            }
          });
        }
  
        Object.keys(priceObject).forEach(key => {
          if (!['userId', '_id', '__v'].includes(key) && typeof priceObject[key] === 'number') {
            sanitizedMaterialPrices[key] = priceObject[key];
          }
        });
      } else {
        console.error("Unexpected material prices format:", materialPricesData);
        return;
      }
  
      // Create the new project with a unique name and include material prices and settings data
      const response = await axios.post('https://projectprice-ad06ee250897.herokuapp.com/form/form', {
        projectName: newProjectName,
        materialPrices: sanitizedMaterialPrices, // Store the current prices with the new project
        settingsData: globalSettingsData, // Include global settings data
      });
  
      const { data, errors } = response.data;
  
      // Log the entire response for debugging
  
      if (errors && errors.length > 0) {
        console.error(errors[0].msg);
      } else {
        // Verify that settingsData is included in the newly created project
        if (!data.settingsData) {
          console.error("Settings data was not saved correctly in the new project.");
        }
  
        // Set the newly created project as the selected project
        setSelectedProject(data);
  
        // Store the project in local storage and update the state
        localStorage.setItem('lastUsedProject', newProjectName);
        setCheckedItems(new Array(52).fill(false));
        setLockedFields({});
        setUserAddedItems([]);
        setMaterialData(sanitizedMaterialPrices);
        setSettingsData(globalSettingsData); // Set global settings data
        fetchProjects();
        setSelectedMaterial(null);
            const initialFormData = {
                projectName: newProjectName,
                sanitizedMaterialPrices,
                settingsData: globalSettingsData,
                checkedItems: initialCheckedItems,
                lockedFields: "",
                userAddedItems: "",
                submitted: false as false,
                inputValue1: 0,
                inputValue2: 0,
                inputValue3: 0,
                inputValue4: 0,
                inputValue5: 0,
                inputValue6: 0,
                inputValue7: 0,
                inputValue8: 0,
                inputValue9: 0,
                inputValue10: 0,
                inputValue11: 0,
                inputValue12: 0,
                inputValue13: 0,
                inputValue14: 0,
                inputValue15: 0,
                inputValue16: 0,
                inputValue17: 0,
                inputValue18: 0,
                inputValue19: 0,
                inputValue20: 0,
                inputValue21: 0,
                inputValue22: 0,
                inputValue23: 0,
                inputValue24: 0,
                inputValue25: 0,
                inputValue26: 0,
                inputValue27: 0,
                inputValue28: 0,
                inputValue29: 0,
                inputValue30: 0,
                inputValue31: 0,
                inputValue32: 0,
                inputValue33: 0,
                inputValue34: 0,
                inputValue35: 0,
                inputValue36: 0,
                inputValue37: 0,
                inputValue38: 0,
                inputValue39: 0,
                inputValue40: 0,
                inputValue41: 0,
                inputValue42: 0,
                inputValue43: 0,
                inputValue44: 0,
                inputValue45: 0,
                inputValue46: 0,
                inputValue47: 0,
                inputValue48: 0,
                inputValue49: 0,
                inputValue50: 0,
                inputValue51: 0,
                inputValue52: 0,
                inputValue53: 0,
                inputValue54: 0,
                inputValue55: 0,
                inputValue56: 0,
                inputValue57: 0,
                inputValue58: 0,
                inputValueSetts: 0,
                pavingWasteCalc: "0", 
                bPavingWasteCalc: "0", 
                pavingHardcoreCalc: "0",
                bPavingHardcoreCalc: "0", 
                postCalc: "0", 
                sandCalc: "0", 
                cementCalc: "0",
                adhesiveCalc:  "0",
                panelCalc:  "0", 
                pavingWasteCalc0:  "0", 
                ballastCalc:  "0", 
                footingCementCalc:  "0", 
                blockworkCalc:  "0",
                wallMetreCalc:  "0", 
                brickworkCalc:  "0",
                buildingSandCalc:  "0", 
                brickSandCalc:  "0", 
                tileAdhesiveCalc:  "0", 
                sleeperCalc:  "0",
                postcreteCalc:  "0", 
                soilCalc:  "0", 
                pavingCalc:  "0", 
                blockPavingCalc:  "0", 
                gravelCalc:  "0", 
                wasteCalc:  "0", 
                hardcoreCalc:  "0", 
                pavingPrice:  "0", 
                bPavingPrice:  "0",
                membraneCalc:  "0",
                labourPrice:  "0",
                marginPrice:  "0",
                pointingCalc:  "0",
                pavingCementCalc:  "0",
                totalWasteCalc:  "0",
                footingCalc:  "0",
                edgingCalc:  "0",
                claddingCalc:  "0",
                deckingCalc: "0",
                stoneFacingCalc: "0",
                renderCalc: "0",
                fasciaCalc: "0",
                frameworkCalc: "0",
                fixingScrewCalc: "0",
                deckingScrewCalc: "0",
                concreteCalc: "0",
                pavingBedCalc: "0",
                concreteDepth: "0",
                concretePadCalc: "0",
                curbCalc: "0",
                rebarCalc: "0",
                pavingDepth: "0",
                projectPrice: "0",
                blockPavingScreed: "0",
                barkCalc: "0",
                bPavingDepth: "0",
                totalCost2:"0",
                pavingHardcore: "0",
                bPavingHardcore: "0",
                buildingSandCement: "0",
                pavingCementBags: "0",
                concretePadHardcore: "0",
                pavingScreedCalc: "0",
                artificialTurfScreed: "0",
                edgingWasteCalc: "0",
                footingWaste: "0",
                settsPointingCalc: "0",
                pavingPointing: "0",
                settsHardcore: "0",
                edgingHardcore: "0",
                settsWaste: "0",
                kilnSand: "0",
                settsCementBags: "0",
                settsCalc: "0",
                artiCalc: "0",
                turfCalc: "0",
                diggerCalc: "0",
                dumperCalc: "0",
                channelDrainCalc: "0",
                pipeCalc: "0",
                gullyCalc: "0",
                fittingsCalc: "0",
                manholeCoverCalc: "0",
                febCalc: "0",
                asphaltHardcore: "0",
                asphaltWaste: "0",
            };
           
            setFormData({
                ...initialFormData, // Contains the default values for the form
                checkedItems: new Array(52).fill(false), // Ensure a new array reference for checkedItems
            });

            setChangesMade(false);
            setProjectNameInput(''); // Clear the project name input field
            
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
};




  const toggleCementDetails = () => {
    setShowCementDetails(!showCementDetails);
  };
  const toggleWasteDetails = () => {
    setShowWasteDetails(!showWasteDetails);
  };
  const toggleHardcoreDetails = () => {
    setShowHardcoreDetails(!showHardcoreDetails);
  };
  const toggleScreedDetails = () => {
    setShowScreedDetails(!showScreedDetails);
  };
  const toggleMembraneDetails = () => {
    setShowMembraneDetails(!showMembraneDetails);
  };
  const togglePointingDetails = () => {
    setShowPointingDetails(!showPointingDetails);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = event.target.checked;
    let updatedFormData: Partial<FormData> = {};

    if (index === 49) {  // If the checkbox with index 49 has changed
        updatedFormData.pavingCalc = updatedCheckedItems[50]
            ? Math.ceil(Number(formData.inputValue1) * 1.1).toFixed(0)
            : Math.ceil((Number(formData.inputValue1) * 1.1) - Number(formData.edgingCalc)).toFixed(0);

        const baseSandCalc = Number(formData.inputValue1) * Number(formData.pavingBedCalc) * 2.2;
        const additionalSandCalc = updatedCheckedItems[50]
            ? baseSandCalc + (Number(formData.edgingCalc) * Number(formData.pavingBedCalc) * 2.2)
            : baseSandCalc;

        updatedFormData.sandCalc = additionalSandCalc.toFixed(2);

    }
    // Set checkedItems state
    setCheckedItems(updatedCheckedItems);

    // Update formData directly for checkedItems and include updatedFormData
    setFormData(prevFormData => ({ 
        ...prevFormData, 
        checkedItems: updatedCheckedItems,
        ...updatedFormData 
    }));
    setShouldRecalculate(true);
};
useEffect(() => {
  saveFormDataToLocalStorage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [formData]);

const handleTitleClick = (section: string) => {
  if (activeSection === section) {
    setActiveSection(''); // or setActiveSection(null);
  } else {
    setActiveSection(section);
  }
}

interface MaterialDataItem {
  materialName: string;
  price: number;
}

const calculateMaterialQuantities = (
  selectedMaterial: string,
  inputValue5: number,
  settingsData: Partial<SettingsData>
): { [key: string]: number } => {
  const selectedFencingMaterial = settingsData.fencingMaterials?.find(
    (material) => material.name === selectedMaterial
  );

  const materialCalculations: { [key: string]: number } = {};

  if (!selectedFencingMaterial) {
    return materialCalculations; // Return empty object if the material is not found
  }

  selectedFencingMaterial.materials.forEach((material: Material, index) => {
    const materialKey = `materialCalc${index}`;
    // Only recalculate if the field is not locked
    if (!lockedFields[materialKey]) {
      const materialQuantity = material.quantity;
      const materialWidth = selectedFencingMaterial.width;
      const calculatedValue = Math.ceil(
        Number(inputValue5) / materialWidth * materialQuantity
      );

      materialCalculations[material.materialName] = calculatedValue;
    } else {
      // If locked, preserve the current value
      materialCalculations[material.materialName] = formData[material.materialName];
    }
  });

  if (selectedFencingMaterial.postMaterial) {
    const postMaterialKey = `${selectedFencingMaterial.postMaterial} post`;
    if (!lockedFields[postMaterialKey]) {
      // Adjust the logic here: Add 1 only if inputValue5 is greater than 0
      const postMaterialQuantity = Math.ceil(Number(inputValue5) / selectedFencingMaterial.width) + (inputValue5 > 0 ? 1 : 0);
      materialCalculations[postMaterialKey] = postMaterialQuantity;
    } else {
      materialCalculations[postMaterialKey] = formData[postMaterialKey];
    }
  }

  return materialCalculations;
};

const materialsBeforeFilter = selectedFencingMaterial?.materials || [];

// First, find the selected group in settingsData.fencingMaterials
const selectedGroup = settingsData.fencingMaterials?.find(
  (material) => material.name === selectedMaterial
);


const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
// Now, filter the materials based on the group
const updatedFencingMaterials: UpdatedMaterial[] = [
  ...selectedGroup?.materials.map((material): UpdatedMaterial => {
    const price = materialData[material.materialName] || 0; // Get the price from materialData
    return {
      materialName: material.materialName,
      displayName: capitalizeFirstLetter(material.materialName), // No change, but still provide a displayName
      quantity: calculateMaterialQuantities(selectedMaterial ?? '', formData.inputValue5, settingsData)[material.materialName] || 0, // Calculate quantity
      price: price, // Use the fetched price
    };
  }) || [],
  ...(selectedGroup?.postMaterial
    ? (() => {
        const postMaterialKey = `${selectedGroup.postMaterial} post`; // Adjust the key to match the saved format
   
        const postMaterialPrice = materialData[postMaterialKey] || 0;

        return [{
          materialName: postMaterialKey,
          displayName: capitalizeFirstLetter(`${selectedGroup.postMaterial} post`), // Capitalize the post material display name
          quantity: calculateMaterialQuantities(selectedMaterial ?? '', formData.inputValue5, settingsData)[selectedGroup.postMaterial] || 0,
          price: postMaterialPrice, // Assign the price
        }];
      })()
    : [])
];

  const calculateValues = (data: FormData, checkedItems: boolean[]): FormData => {
    
    const {
      inputValue1,
      inputValue2,
      inputValue3,
      inputValue18,
      inputValue20,
      inputValue5,
      inputValue7,
      inputValue16,
      inputValue17,
      inputValue19,
      inputValue22,
      inputValue23,
      inputValue24,
      inputValue25,
      inputValue44,
      inputValue47,
      inputValue48,
      inputValue49,
      inputValue50,
    } = data;
    const materialCalculations = calculateMaterialQuantities(selectedMaterial ?? '', inputValue5, settingsData);

    const deckingWidth = (
      ((Number(settingsData['Decking board width']) + 5) * 0.001)
    ).toFixed(3); 

    const bPavingHardcore = (
      (Number(settingsData['Block paving sub-base depth']) * 0.001)
    ).toFixed(2);

    const pavingHardcore = (
      (Number(settingsData['Paving sub-base depth']) * 0.001)
    ).toFixed(2);

    const edgingDiam = (
      (Number(settingsData['Edging width']) * 0.001)
    ).toFixed(2);

    const bPavingHardcoreCalc = (
            (Number(inputValue2) * (Number(bPavingHardcore)) * 1.92).toFixed(2)
    );

  

    const pavingBedCalc = (
      Number(settingsData['Paving bed depth']) * .001
    ).toFixed(2);

    const pavingDepth = (
      (Number(pavingHardcore) + Number(pavingBedCalc) + 0.02)
    ).toFixed(2);

    const edgingCalc = (Number(formData.inputValue3) * Number(edgingDiam)).toFixed(2);

    const edgingHardcore = (
      (Number(edgingCalc) * (Number(pavingHardcore) * 1.92)).toFixed(2)
    );

    const pavingHardcoreCalc = (checkedItems[50] ?
            (Number(inputValue1) * (Number(pavingHardcore) * 1.92)) :
            (Number(inputValue1) * (Number(pavingHardcore) * 1.92) + Number(edgingHardcore))
    ).toFixed(2);

    const updatedSandCalc = (Number(edgingCalc) * (Number(pavingBedCalc)) * 2.2).toFixed(2);

    const sandCalc = (
      checkedItems[50]
      ? (Number(inputValue1) * Number(pavingBedCalc) * 2.2).toFixed(2)
      : (Number(inputValue1) * Number(pavingBedCalc) * 2.2 + Number(updatedSandCalc)).toFixed(2)
  );

  const asphaltHardcoreDepth = (
    (Number(settingsData['Asphalt sub-base depth']) * 0.001).toFixed(2)
  );

  const asphaltHardcore = (
    (Number(formData.inputValue56) * Number(asphaltHardcoreDepth)).toFixed(2)
  );

    const pavingWaste = (
      Number(pavingDepth) * 2
    ).toFixed(2);

    const wallMetreCalc = (checkedItems[48]
      ? (Number(data.inputValue8) * Number(data.inputValue7)) * 2
      : (Number(data.inputValue8) * Number(data.inputValue7))
    ).toFixed(2);

    const asphaltDepth = (
      (Number(settingsData['Asphalt depth']) * 0.001)
    ).toFixed(2);

    const asphaltRemovedDepth = (
      (Number(asphaltDepth) + Number(asphaltHardcoreDepth)).toFixed(2)
    );
    
    const sparGap = (
      (Number(settingsData['Decking gap between joists']) )
    ).toFixed(2);

    const fasciaHeight = (
      (Number(settingsData['Fascia board height']) * 0.001)
    ).toFixed(2);

    const fasciaLength = (
      (Number(settingsData['Fascia board length']))
    ).toFixed(2);

    const fasciaBoard = (
      (Number(fasciaHeight) * Number(fasciaLength))
    ).toFixed(2);

    
    const ballastSettingsCalc = (
      ((Number(settingsData['Footing width']) * 0.001) * (Number(settingsData['Footing depth']) * 0.001))
    );

    const ballastCalc = (
      (Number(inputValue7) * (Number(ballastSettingsCalc) * 2.2)).toFixed(2)
    );

    const footingWaste = ( checkedItems[51] ? 
      Number(ballastCalc) : 0
    ).toFixed(2);

    const actualFootingRatio = (
      Number(ballastCalc) / ((Number(settingsData['Ballast / cement ratio']) + 1))
    );

    const footingMortar = (
      (Number(inputValue7) * (Number(ballastSettingsCalc)) * 2.2)
    ).toFixed(2);

    const footingCementCalc = (checkedItems[51] ?
      (Number(actualFootingRatio) / 0.025 ).toFixed(2) :
      0
    );

    const sparFrontBack = (
      Number(formData.inputValue6) * 2
    )

    const totalDeckingArea = (
      (Number(formData.inputValue6 || 0) * Number(formData.inputValue46 || 0)).toFixed(2)
    );

    const deckingDimensions = (
      (Number(deckingWidth) * (Number(settingsData['Decking board length']))).toFixed(2)
    );

    const deckingBoardTotal = (
      (Number(formData.inputValue55) / Number(deckingDimensions)).toFixed(2)
    );

    const sparRowsValue = Number(formData.inputValue6 || 0) / Number(sparGap);
    
    const sparRows = (sparRowsValue > 0 ? sparRowsValue + 1 : sparRowsValue).toFixed(2);
    
    const noggingLength = (
      ((Number(sparRows) - 1) * 0.8).toFixed(2)
    );

    const sparMeterForSquare = (
      (Number(sparRows) * Number(formData.inputValue46 || 0)).toFixed(2)
    );

    const sparPerSquareMetre = (
      (Number(sparMeterForSquare) / Number(totalDeckingArea || 1)).toFixed(2)
    );

    const ourSparPerSquareMeter = (
      ((Number(sparPerSquareMetre) * Number(formData.inputValue55)) + Number(sparFrontBack) + Number(noggingLength)).toFixed(2)
    );

    const sparLengthNeeded = (
      (Number(ourSparPerSquareMeter) / (Number(settingsData['Decking frame timber length']))).toFixed(2)
    );

    const deckingScrews = (
      (Number(deckingBoardTotal) * 2.4 / Number(sparGap) * 2).toFixed(2)
    );

    const concreteDepth = (
      Number(settingsData['Concrete pad depth']) * 0.001
    );

    const panelCalc = (
          Math.ceil(Number(inputValue5) / Number(settingsData['Fence bay length'])).toFixed(2)
    );

    const deckingPosts = (
      (((Number(formData.inputValue55) / 2) * Number(inputValue44)) / Number(settingsData['Post length'])).toFixed(2)
    );

    const postCalc = (
      selectedFencingMaterial?.width 
      ? Math.ceil(Number(deckingPosts)).toFixed(2)
      : (inputValue5 && Number(inputValue5) !== 0 
          ? Math.ceil(Number(deckingPosts) + ((Number(panelCalc) + 1))).toFixed(0) 
          : Math.ceil(Number(deckingPosts)).toFixed(2))
    );
    

    const curbWidth = (
      (Number(settingsData['Curb length']) * 0.001).toFixed(2)
    ); 

    const actualBarkCalc = (
      (Number(settingsData['Bark depth']) * 0.001).toFixed(2)
    );

    const screedCalc = (
      (Number(settingsData['Screed depth']) * 0.001).toFixed(2)
    );

    const pavingScreed = (
      (Number(inputValue2) * (Number(screedCalc)))
    );
    
    const pavingScreedCalc = (
      (Number(pavingScreed) * 2)
    );

    const artificialTurfScreed = (
      (Number(formData.inputValue51)) * Number(screedCalc)
    );

    const artificialTurfScreedSum = (
      (Number(artificialTurfScreed) * 2)
    );

    const bPavingDepth = (
      Number(bPavingHardcore) + Number(screedCalc) + 0.05
    ).toFixed(2);

    const sleepers = Math.ceil (Number(inputValue17) / Number(settingsData['Sleeper length']));

    const blockworkCalc = (Number(wallMetreCalc) / 0.1).toFixed(0);

    const brickworkCalc = (Number(wallMetreCalc) / 0.017).toFixed(0);

    const brickSandCalc = (checkedItems[25]
      ? "0"                   
      : (Number(brickworkCalc) * 0.001).toFixed(2));
    
    const blockSandCalc = (checkedItems[24]
      ? "0"
      : (Number(blockworkCalc) * 0.003).toFixed(2));

    const concretePadHardcore = (Number(inputValue47) * Number(pavingHardcore) * 1.92);

    const bPavingWasteCalc = (Number(inputValue2) * Number(bPavingDepth) * 2);

    const buildingSandCalc = (Number(blockSandCalc) + Number(brickSandCalc)).toFixed(2)

    const buildingSandRatio = (Number(buildingSandCalc) / (Number(settingsData['Building sand / cement ratio']) + 1)
    );
    
    const buildingSandCement = (
      (Number(buildingSandRatio)) / 0.025
    ).toFixed(2)

    const gravelDepth = (
      Number(settingsData['Gravel depth']) * 0.001
    ).toFixed(2);


    const fixingScrew = (
      (Number(sparRows) * 6)
    );

    const asphaltWaste = (
      (Number(formData.inputValue56) * Number(asphaltRemovedDepth)).toFixed(2)
    );
    
    const totalCost2 = totalCost.toFixed(2);

    const mortarTotal = ((Number(inputValue1) * Number(pavingBedCalc) * 2.2) * 1.1).toFixed(2);

    const cementRatio = (Number(settingsData['Paving sand / cement ratio']) + 1).toFixed(2);

    const cementVolume = (Number(mortarTotal) / Number(cementRatio)).toFixed(2);
 
    const sandVolume = (Number(mortarTotal) - Number(cementVolume));

    const mortarRatio = (
      Number(mortarTotal) / (Number(settingsData['Paving sand / cement ratio']) + 1)
    ).toFixed(2);

    const pavingCementBags =(
     (Number(mortarRatio) / 0.025)
    ).toFixed(2);

    const settsHardcore = (
      Number(formData.inputValueSetts) * Number(bPavingHardcore) * 1.92
    ).toFixed(2);

    const settsPointingCalc = (Number(formData.inputValueSetts) / Number(settingsData['Pointing coverage'])) * 3;

    const edgingWasteCalc = (checkedItems[50] ? 0 :
      Number(edgingCalc) * Number(pavingDepth)).toFixed(2);

      const settsWaste = (Number(formData.inputValueSetts) * (Number(bPavingDepth) * 2)).toFixed(2);

      const settsMortar = (Number(formData.inputValueSetts) * Number(pavingBedCalc) * 2.2).toFixed(2);
      const settsCement = (Number(settsMortar) / (Number(settingsData['Paving sand / cement ratio']) + 1)).toFixed(2);
      const settsCementBags = (Number(settsCement) / 0.025);
      const settsSand = (Number(settsMortar) - Number(settsCement));
      const pavingPointing = checkedItems[50] 
    ? Number(formData.inputValue1) / Number(settingsData['Pointing coverage'])
    : (Number(formData.inputValue1) + Number(edgingCalc)) / Number(settingsData['Pointing coverage']);
      
    const updatedData: FormData = {
      ...data,
      ...materialCalculations,
      rebarCalc: (checkedItems[49]
      ? ((Number(inputValue47) + Number(inputValue1)) / 2.88).toFixed(2)
      : (Number(inputValue47) / 2.88).toFixed(2)
      ),
      bPavingHardcore,
      asphaltWaste,
      asphaltCalc: (Number(asphaltDepth) * Number(formData.inputValue56)).toFixed(2),
      frameworkCalc: Math.ceil(Number(sparLengthNeeded) || 0).toFixed(2),
      curbCalc: Math.ceil(inputValue49 / Number(curbWidth) * inputValue48).toFixed(2),
      concretePadCalc: (Number(inputValue47) * Number(concreteDepth)).toFixed(2),
      concreteDepth: (Number(settingsData['Concrete pad depth']) * 0.001).toFixed(2),
      pavingBedCalc: (Number(settingsData['Paving bed depth']) * 0.001).toFixed(2),
      concreteCalc: (checkedItems[49]
      ? ((Number(inputValue1) + (Number(inputValue47))) * Number(concreteDepth) * 2.2).toFixed(2)
      : (Number(inputValue47) * Number(concreteDepth) * 2.2).toFixed(2)
      ),
      asphaltHardcore,
      fixingScrewCalc: Math.ceil(Number(fixingScrew)).toFixed(2),
      deckingScrewCalc: Math.ceil(Number(deckingScrews)).toFixed(2),
      fasciaCalc: (Number(formData.inputValue44) * Number(formData.inputValue45) / (Number(fasciaBoard))).toFixed(2),
      deckingCalc: Math.ceil(Number(deckingBoardTotal)).toFixed(2) ,
      edgingCalc: (Number(formData.inputValue3) * Number(edgingDiam)).toFixed(2),
      wallMetreCalc: (checkedItems[48]
      ? (Number(formData.inputValue7) * Number(formData.inputValue8) * 2).toFixed(2)
      : (Number(formData.inputValue7) * Number(formData.inputValue8)).toFixed(2)),
      pavingPointing: (checkedItems[50] 
      ? Number(inputValue1) / Number(settingsData['Pointing coverage'])
      : (Number(inputValue1) + Number(edgingCalc)) / Number(settingsData['Pointing coverage'])).toFixed(2),
      pointingCalc: Math.ceil(pavingPointing + settsPointingCalc).toFixed(2),
      membraneCalc: (checkedItems[50]
      ? Math.ceil(Number(formData.inputValue1) + Number(formData.inputValue2) + Number(formData.inputValueSetts)).toFixed(2)
      : Math.ceil(Number(formData.inputValue1) + Number(formData.inputValue2) + Number(formData.inputValueSetts) + Number(edgingCalc)).toFixed(2)),
      bPavingWasteCalc: (Number(inputValue2) * Number(bPavingDepth) * 2).toFixed(2),
      bPavingHardcoreCalc: (Number(inputValue2) * (Number(bPavingHardcore)) * 1.92).toFixed(2),
      blockPavingCalc: Math.ceil(Number(inputValue2) * 1.1).toFixed(2),
      ballastCalc: (checkedItems[51]
      ? (Number(inputValue7) * (Number(ballastSettingsCalc) * 2.2)).toFixed(2)
      : "0.00"),
      footingCementCalc: (checkedItems[51]
      ? (Number(actualFootingRatio) / 0.025 ).toFixed(2)
      : "0"
      ),
      blockworkCalc: Math.ceil(Number(wallMetreCalc) / 0.1).toFixed(2),
      brickworkCalc: Math.ceil(Number(wallMetreCalc) / 0.017).toFixed(2),
      renderCalc: (Number(wallMetreCalc) * 0.012 * 2.2).toFixed(2),
      claddingCalc: (Number(wallMetreCalc)).toFixed(2),
      buildingSandCalc: (Number(blockSandCalc) + Number(brickSandCalc)).toFixed(2),
      stoneFacingCalc: (Number(wallMetreCalc) / 2).toFixed(2),
      brickSandCalc: (Number(brickworkCalc) * 0.001).toFixed(2),
      tileAdhesiveCalc: (checkedItems[49]
      ? Math.ceil((Number(inputValue1) + (Number(wallMetreCalc))) / Number(settingsData['Tile adhesive coverage'])).toFixed(2)
      : Math.ceil(Number(wallMetreCalc) / Number(settingsData['Tile adhesive coverage'])).toFixed(2)),
      pavingWasteCalc: (Number(data.inputValue1) * (Number(pavingDepth) * 2)).toFixed(2),
      pavingHardcoreCalc: (Number(inputValue1) * (Number(pavingHardcore) * 1.92)).toFixed(2),
      sandCalc: (checkedItems[49]
        ? "0.00" 
        : (checkedItems[50]
            ? (sandVolume + settsSand).toFixed(2)
            : (sandVolume + Number(updatedSandCalc) + settsSand).toFixed(2)
          )
      ),
      edgingHardcore: Number(edgingHardcore).toFixed(2),
      buildingSandCement: (
        (Number(buildingSandCalc) / Number(settingsData['Building sand / cement ratio'])) / 0.025
      ).toFixed(2),
      cementCalc: (checkedItems[49]
      ? (Number(footingCementCalc) + Number(buildingSandCement) + Number(settsCementBags)).toFixed(2)
      : Math.ceil((Number(pavingCementBags) + Number(footingCementCalc) + Number(buildingSandCement) + Number(settsCementBags))).toFixed(0)
      ),
      totalWasteCalc: ((Number(data.inputValue1) * (Number(pavingWaste))) + (Number(bPavingWasteCalc)) + (Number(data.inputValue4) + Number(settsWaste)) + Number(footingWaste) + Number(edgingWasteCalc) + Number(asphaltWaste)).toFixed(2),
      hardcoreCalc: (Number(bPavingHardcoreCalc) + Number(pavingHardcoreCalc) + Number(settsHardcore) + Number(concretePadHardcore) + Number(data.inputValue21) + Number(asphaltHardcore)).toFixed(2),
      adhesiveCalc: (checkedItems[50]
        ? Math.ceil(Number(inputValue1) / (Number(settingsData['Primer coverage']))).toFixed(2)
       : Math.ceil((Number(inputValue1) + Number(edgingCalc)) / (Number(settingsData['Primer coverage']))).toFixed(2)),
      pavingCalc: (checkedItems[50] 
        ? Math.ceil(Number(inputValue1) * 1.1 - Number(edgingCalc)).toFixed(2) 
        : Math.ceil(Number(inputValue1) * 1.1).toFixed(2)),
      panelCalc: Math.ceil(Number(inputValue5) / Number(settingsData['Fence bay length'])).toFixed(2),
      postCalc: selectedMaterial && selectedFencingMaterial?.width
        ? Math.ceil(Number(deckingPosts)).toFixed(2)
        : (inputValue5 && Number(inputValue5) !== 0 
            ? Math.ceil(Number(deckingPosts) + ((Number(panelCalc) + 1))).toFixed(0) 
            : Math.ceil(Number(deckingPosts)).toFixed(2)),
            postcreteCalc: selectedMaterial && selectedFencingMaterial?.width
            ? Math.ceil(
                (Number(inputValue5) / Number(selectedFencingMaterial.width) + (Number(inputValue5) > 0 ? 1 : 0)) * 
                Number(settingsData['Postcrete per post'])
              ).toFixed(2)
            : (Number(postCalc) * Number(settingsData['Postcrete per post'])).toFixed(2),
                 
      sleeperCalc: (Number(sleepers) * Number(inputValue16)).toFixed(2),
      gravelCalc: (Number(inputValue18) * Number(gravelDepth) * 1.92).toFixed(2),
      soilCalc: ((Number(inputValue20) * 0.001) * (Number(inputValue19) * 1.7)).toFixed(2),
      pavingPrice: (Number(inputValue1) * 1.1 * Number(inputValue22)).toFixed(2),
      bPavingPrice: (Number(inputValue2) * Number(inputValue23)).toFixed(2),
      labourPrice:(Number(inputValue24) * Number(inputValue25)).toFixed(2),
      projectPrice: (checkedItems[52] 
        ? (Number(labourPrice) + Number(labourVatPrice) + Number(marginTotal)).toFixed(2)
        : (Number(labourPrice) + Number(marginTotal)).toFixed(2)),
      pavingDepth: (Number(pavingHardcore) + Number(pavingBedCalc) + 0.02).toFixed(2),
      pavingScreedCalc: (Number(pavingScreed) * 2).toFixed(2),
      artificialTurfScreed: (Number(artificialTurfScreed) * 2).toFixed(2),
      blockPavingScreed: (Number(pavingScreedCalc) + Number(artificialTurfScreedSum)).toFixed(2),
      barkCalc: (Number(inputValue50) * Number(actualBarkCalc) * 1.8).toFixed(2),
      bPavingDepth:(Number(bPavingHardcore) + Number(screedCalc) + 0.05).toFixed(2),
      totalCost2,
      pavingHardcore,
      footingWaste: (checkedItems[51] ? Number(ballastCalc) : 0).toFixed(2),
      pavingCementBags: (Number(mortarRatio) / 0.025).toFixed(2),
      concretePadHardcore: (Number(inputValue47) * Number(pavingHardcore) * 1.92).toFixed(2),
      settsHardcore: (Number(formData.inputValueSetts) * Number(bPavingHardcore) * 1.92).toFixed(2),
      edgingWasteCalc: (checkedItems[50] ? 0 :
        Number(edgingCalc) * Number(pavingDepth)).toFixed(2),
      settsPointingCalc: ((Number(formData.inputValueSetts) / Number(settingsData['Pointing coverage'])) * 3).toFixed(2),
      settsWaste: (Number(formData.inputValueSetts) * (Number(bPavingDepth) * 2)).toFixed(2),
      kilnSand: Math.ceil(Number(inputValue2) / 5 ).toFixed(2),
      settsCementBags: (Number(settsCement) / 0.025).toFixed(2),
      settsCalc: Math.ceil(Number(formData.inputValueSetts) * 1.1).toFixed(0),
      artiCalc: (Number(formData.inputValue51)).toFixed(0),
      turfCalc: (Number(formData.inputValue19)).toFixed(0),
      diggerCalc: (Number(formData.inputValue14)).toFixed(0),
      dumperCalc: (Number(formData.inputValue15)).toFixed(0),
      channelDrainCalc: (Number(formData.inputValue9)).toFixed(0),
      pipeCalc: (Number(formData.inputValue10)).toFixed(0),
      gullyCalc: (Number(formData.inputValue11)).toFixed(0),
      fittingsCalc: (Number(formData.inputValue12)).toFixed(0),
      manholeCoverCalc: (Number(formData.inputValue13)).toFixed(0),
      febCalc: checkedItems[49] ?
      "0" :
      ((Number(mortarTotal) + Number(buildingSandCalc)) * 0.1).toFixed(2)
    ,
    };
    return updatedData;
    
  };

  useEffect(() => {
    if (shouldRecalculate) {
        const updatedData = calculateValues(formData, checkedItems);
      
        setFormData(prevData => {
            const newData = { ...prevData };
            for (const key in updatedData) {
                if (!lockedFields[key]) { // Only update if the field is not locked
                    newData[key] = updatedData[key];
                }
            }
            return newData;
        });
    }
    saveFormDataToLocalStorage();
    setShouldRecalculate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRecalculate, selectedMaterial, selectedFencingMaterial, lockedFields, checkedItems[50], checkedItems[24], checkedItems[25], checkedItems[51], checkedItems[48], checkedItems[49], formData.inputValue51, formData.inputValue15, formData.inputValue14, formData.inputValue24, 
     formData.inputValue25, formData.inputValue26, formData.inputValueSetts,
     formData.inputValue27,formData.inputValue28, formData.inputValue29, formData.inputValue30, 
     formData.inputValue32, formData.inputValue33, formData.inputValue34, formData.inputValue35, 
     formData.inputValue36, formData.inputValue40, formData.inputValue41, formData.inputValue42, 
     formData.inputValue43, formData.inputValue37, formData.inputValue50, formData.inputValue51,
     formData.inputValue19, formData.inputValue14, formData.inputValue15, formData.inputValue9,
     formData.inputValue10, formData.inputValue11, formData.inputValue12, formData.inputValue13,
     formData.inputValue47, formData.inputValue48, formData.inputValue49, formData.inputValue1, 
     formData.inputValue2, formData.inputValue3, formData.inputValue4, formData.inputValue5, 
     formData.inputValue6, formData.inputValue7, formData.inputValue8, formData.inputValue17, 
     formData.inputValue18, formData.inputValue20, formData.inputValue21, formData.inputValue22, 
     formData.inputValue23, formData.inputValue44, formData.inputValue45, formData.inputValue46,
     formData.inputValue55, formData.inputValue56, formData.inputValue57, formData.inputValue58
    ]);

    function calculateMaterialPrice(materialDataKey: keyof typeof materialData, formDataKey: keyof typeof formData): number {
      const materialValue = Number(materialData[materialDataKey]);
      const formValue = Number(formData[formDataKey]);
    
      // Handle NaN or invalid numbers by returning 0
      if (isNaN(materialValue) || isNaN(formValue)) {
        return 0;
      }
    
      return Number((materialValue * formValue).toFixed(2));
    }
    
  
const wastePrice = calculateMaterialPrice('Waste', 'totalWasteCalc');
const pavingPrice = formData.inputValue22 * Number(formData.pavingCalc);
const bPavingPrice = formData.inputValue23 * Number(formData.inputValue2);
const hardcorePrice = calculateMaterialPrice('Hardcore', 'hardcoreCalc');
const edgingPrice = calculateMaterialPrice('Edgings', 'edgingCalc');
const membranePrice = calculateMaterialPrice('Membrane', 'membraneCalc');
const sandPrice = calculateMaterialPrice('Paving sand', 'sandCalc');
const cementPrice = calculateMaterialPrice('Cement', 'cementCalc');
const adhesivePrice = calculateMaterialPrice('Primer', 'adhesiveCalc');
const postPrice = calculateMaterialPrice('Posts', 'postCalc');
const panelPrice = calculateMaterialPrice('Fencing bay', 'panelCalc');
const postcretePrice = calculateMaterialPrice('Postcrete', 'postcreteCalc');
const ballastPrice = calculateMaterialPrice('Ballast', 'ballastCalc');
const brickworkPrice = calculateMaterialPrice('Brick work', 'brickworkCalc');
const blockworkPrice = calculateMaterialPrice('Block work', 'blockworkCalc');
const buildingSandPrice = calculateMaterialPrice('Building sand', 'buildingSandCalc');
const claddingPrice = calculateMaterialPrice('Cladding', 'claddingCalc');
const tileAdhesivePrice = calculateMaterialPrice('Tile adhesive', 'tileAdhesiveCalc');
const sleepersPrice = calculateMaterialPrice('Sleepers', 'sleeperCalc');
const channelDrainPrice = calculateMaterialPrice('Channel drains', 'channelDrainCalc');
const drainagePipePrice = calculateMaterialPrice('Drainage pipe', 'pipeCalc');
const gullyPotPrice = calculateMaterialPrice('Gully pot', 'gullyCalc');
const drainageFittingsPrice = calculateMaterialPrice('Drainage fittings', 'fittingsCalc');
const manholeCoverPrice = calculateMaterialPrice('Manhole cover', 'manholeCoverCalc');
const gravelPrice = calculateMaterialPrice('Gravel', 'gravelCalc');
const turfPrice = calculateMaterialPrice('Turf', 'turfCalc');
const soilPrice = calculateMaterialPrice('Soil', 'soilCalc');
const pointingPrice = calculateMaterialPrice('Pointing', 'pointingCalc');
const diggerPrice = calculateMaterialPrice('Digger', 'diggerCalc');
const dumperPrice = calculateMaterialPrice('Dumper', 'dumperCalc');
const deckingPrice = calculateMaterialPrice('Decking', 'deckingCalc');
const stoneFacingPrice = calculateMaterialPrice('Stone facing', 'stoneFacingCalc');
const renderPrice = calculateMaterialPrice('Render', 'renderCalc');
const fasciaPrice = calculateMaterialPrice('Decking fascia board', 'fasciaCalc');
const fixingScrewPrice = calculateMaterialPrice('Fixing screws', 'fixingScrewCalc');
const deckingScrewPrice = calculateMaterialPrice('Decking screws', 'deckingScrewCalc');
const deckingFramePrice = calculateMaterialPrice('Decking frame work timber', 'frameworkCalc');
const concretePrice = calculateMaterialPrice('Concrete', 'concreteCalc');
const curbPrice = calculateMaterialPrice('Curb', 'curbCalc');
const rebarPrice = calculateMaterialPrice('Rebar sheet', 'rebarCalc');
const blockPavingScreedPrice = calculateMaterialPrice('Screed material', 'blockPavingScreed');
const barkPrice = calculateMaterialPrice('Bark', 'barkCalc');
const artificialTurfPrice = calculateMaterialPrice('Artificial turf', 'artiCalc');
const settsPrice = calculateMaterialPrice('Setts', 'settsCalc');
const KilnSandPrice = calculateMaterialPrice('Kiln sand', 'kilnSand');
const SkipPrice = calculateMaterialPrice('Skip', 'inputValue53');
const plasticiserPrice = calculateMaterialPrice('Plasticiser concentrate', 'febCalc');
const asphaltPrice = calculateMaterialPrice('Asphalt', 'asphaltCalc');



const InputValues = [
  Number(formData.inputValue37),
  Number(formData.inputValue38),
  Number(formData.inputValue39),
  Number(formData.inputValue42),
  Number(formData.inputValue52),
  Number(formData.inputValue54),
];

const marginPrice = Number(formData.inputValue35) / 100

  const labourTime = 
  (
  Number(formData.inputValue26 || 0) +
  Number(formData.inputValue27 || 0) +
  Number(formData.inputValue28 || 0) +
  Number(formData.inputValue29 || 0) +
  Number(formData.inputValue30 || 0) +
  Number(formData.inputValue31 || 0) +
  Number(formData.inputValue32 || 0) +
  Number(formData.inputValue34 || 0) +
  Number(formData.inputValue36 || 0) +
  Number(formData.inputValue40 || 0) +
  Number(formData.inputValue41 || 0) +
  Number(formData.inputValue43 || 0) +
  Number(formData.inputValue57 || 0) +
  Number(formData.inputValue58 || 0)
  ).toFixed(1);

  let totalCost = 0;
  const userAddedItemPrices = userAddedItems.map(item => {
    // Treat empty, undefined, or null values as 0
    const price = item.price ? Number(item.price) : 0;
    return isNaN(price) ? 0 : price;
  });
  
  // Assuming updatedFencingMaterials has the price property
  const totalFencingMaterialPrice = updatedFencingMaterials.reduce((total, material) => {
    const calculatedValue = formData[material.materialName] || 0;
    const materialPrice = Number(material.price) * calculatedValue;
    return total + materialPrice;
  }, 0);
  


  const materialPrices = [
    Number(wastePrice),
    Number(hardcorePrice),
    Number(pavingPrice),
    Number(edgingPrice),
    Number(bPavingPrice),
    Number(blockPavingScreedPrice),
    Number(membranePrice),
    Number(adhesivePrice),
    Number(pointingPrice),
    Number(curbPrice),
    Number(sandPrice),
    Number(cementPrice),
    Number(ballastPrice),
    Number(buildingSandPrice),
    Number(concretePrice),
    Number(rebarPrice),
    Number(postPrice),
    Number(panelPrice),
    Number(postcretePrice),
    Number(deckingPrice),
    Number(deckingFramePrice),
    Number(fixingScrewPrice),
    Number(deckingScrewPrice),
    Number(fasciaPrice), 
    Number(blockworkPrice),
    Number(brickworkPrice),
    Number(claddingPrice),
    Number(tileAdhesivePrice),
    Number(stoneFacingPrice),
    Number(renderPrice),
    Number(channelDrainPrice),
    Number(drainagePipePrice),
    Number(gullyPotPrice),
    Number(drainageFittingsPrice),
    Number(manholeCoverPrice),
    Number(sleepersPrice),
    Number(gravelPrice),
    Number(barkPrice),
    Number(artificialTurfPrice),
    Number(turfPrice),
    Number(soilPrice),
    Number(diggerPrice),
    Number(dumperPrice),
    Number(settsPrice),
    Number(KilnSandPrice),
    Number(SkipPrice),
    Number(plasticiserPrice),
    Number(asphaltPrice),
    ...InputValues,
    ...userAddedItemPrices,
  ];

// Calculate total cost considering both static and dynamic materials
totalCost = materialPrices.reduce((acc, price, idx) => {
  if (!checkedItems[idx]) {
    acc += price;
  }
  return acc;
}, 0);

// Add the cost for dynamic materials starting from index 53
updatedFencingMaterials.forEach((material, index) => {
  const calculatedValue = formData[material.materialName] || 0;
  const materialPrice = Number(material.price) * calculatedValue;

  // Only add the price if the corresponding checkbox is not checked
  if (!checkedItems[53 + index]) {
    totalCost += materialPrice;
  }
});



const labourPrice = 
(Number(formData.inputValue24 || 0) *
(Number(formData.inputValue25 || 0) +
Number(formData.inputValue26 || 0) +
Number(formData.inputValue27 || 0) +
Number(formData.inputValue28 || 0) +
Number(formData.inputValue29 || 0) +
Number(formData.inputValue30 || 0) +
Number(formData.inputValue31 || 0) +
Number(formData.inputValue32 || 0) +
Number(formData.inputValue33 || 0) +
Number(formData.inputValue34 || 0) +
Number(formData.inputValue36 || 0) +
Number(formData.inputValue40 || 0) +
Number(formData.inputValue41 || 0) +
Number(formData.inputValue43 || 0) +
Number(formData.inputValue57 || 0) +
Number(formData.inputValue58 || 0)
)
).toFixed(2);

const labourVatPrice = (Number(labourPrice) * 0.2).toFixed(2);
const labourAddedVat = (Number(labourPrice) * 1.2).toFixed(2);
const totalCost2 = totalCost.toFixed(2);
const materialVat = (Number(totalCost2) * 0.2).toFixed(2);
const vatPrice = checkedItems[52] 
? (Number(labourVatPrice) + Number(materialVat)).toFixed(2)
: Number(materialVat).toFixed(2);
const totalCostAfter = (Number(totalCost2) * 1.2).toFixed(2);
const addedMargin = (Number(marginPrice) * Number(totalCost2)).toFixed(2);
const marginTotal = checkedItems[52]
? (Number(addedMargin) + Number(totalCostAfter) + (Number(labourVatPrice))).toFixed(2)
: (Number(addedMargin) + Number(totalCostAfter)).toFixed(2);
const projectPrice = (checkedItems[52] 
? (Number(labourPrice) + Number(labourVatPrice) + Number(marginTotal)).toFixed(2)
: (Number(labourPrice) + Number(marginTotal)).toFixed(2));


  return (
    <ContentWrapper>
      <Notification onHighlightButton={handleHighlightButton} />
  <MainContainer>
    <TablesContainer>
    <CreateProjectContainer>
    <ProjectContainer>
    <ButtonWrapper>
    <ProjectNameInput
    className="project-name-input"
    type="text"
    value={projectNameInput}
    onChange={(e) => {
      setProjectNameInput(e.target.value);
      setTyping(true);
    }}
    onBlur={() => setTyping(false)}
    placeholder="Project name"
  />

<ProjectButton style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}
  onClick={createProject}
>
  Create new project
</ProjectButton>

</ButtonWrapper>

{projects.length > 0 ? (
      <ActionsContainer>
      
        <SelectDropdown
          value={selectedProject?._id}
          onChange={handleProjectChange}
        >
          <option value="">Select a project</option>
          {projects.map((project) => (
            <option key={project._id} value={project._id}>
              {project.projectName}
            </option>
          ))}
        </SelectDropdown>
      
      </ActionsContainer>
    ) : (
      <div>No projects saved</div>
    )}
  </ProjectContainer>
</CreateProjectContainer>
  <ValueContainer className="value-input" isMinimized={isValueMinimized} isProjectSelected={!!selectedProject}>
        <ResultTable>
        <thead>
      <tr>
        <ResultTableHeader style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}>Materials</ResultTableHeader>
        <ResultTableHeader style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}>{isValueMinimized ? "" : "Value"} <MinimizeButton onClick={toggleValueMinimized}>
          {isValueMinimized ? "+" : "-"}
        </MinimizeButton>
        </ResultTableHeader>
      </tr>
    </thead>
    <tbody>
            <ResultTableRow>
              <ColspanCell colSpan={2}>
            <TitleDiv onClick={() => handleTitleClick('paving')}>Paving</TitleDiv>
            </ColspanCell>
            </ResultTableRow>
            {activeSection === 'paving' && (
    <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue1) === 0 || formData.inputValue1 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue1}>
            Paving area:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue1}>
            <Input
            type="number"
            name="inputValue1"
            disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
            title={!selectedProject ? 'Create new project to use' : undefined}
            value={formData.inputValue1}
            onChange={handleChange}
            onClick={() => {
              if (formData.inputValue1 === 0) {
                handleChange({ target: { name: 'inputValue1', value: '' } } as React.ChangeEvent<HTMLInputElement>);
              }
            }}
          />{" "}
          m²
        </ResultTableDataOrig>
          </ResultTableRow>
        
            )}
             {activeSection === 'paving' && (
          <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue22) === 0 || formData.inputValue22 === 0)}>
          <ResultTableDataOrig>
            Paving slab price:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
            <Input
              type="number"
              name="inputValue22"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue22}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue22 === 0) {
                  handleChange({ target: { name: 'inputValue22', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            per m²
          </ResultTableDataOrig>
          </ResultTableRow>
            )}

{ (activeSection === 'paving') && (
          <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue3) === 0 || formData.inputValue3 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue3}>
            Linear edgings:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue3}>
            <Input
              type="number"
              name="inputValue3"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue3}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue3 === 0) {
                  handleChange({ target: { name: 'inputValue3', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            m
            </ResultTableDataOrig>
            </ResultTableRow>
            )}

{ (activeSection === 'paving') && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue48) === 0 || formData.inputValue48 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue48}>
            Curb steps:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue48}>
            <Input
              type="number"
              name="inputValue48"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue48}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue48 === 0) {
                  handleChange({ target: { name: 'inputValue48', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "} 
            </ResultTableDataOrig>
            </ResultTableRow>
)}
{ (activeSection === 'paving') && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue49) === 0 || formData.inputValue49 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue49}>
            Step width:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue49}>
            <Input
              type="number"
              name="inputValue49"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue49}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue49 === 0) {
                  handleChange({ target: { name: 'inputValue49', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            m
            </ResultTableDataOrig>
            </ResultTableRow>
)}

{ (activeSection === 'paving') && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue4) === 0 || formData.inputValue4 === 0)}>
          <ResultTableDataOrig>
            Additional waste:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
            <Input
              type="number"
              name="inputValue4"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue4}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue4 === 0) {
                  handleChange({ target: { name: 'inputValue4', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            ton
            </ResultTableDataOrig>
            </ResultTableRow>
            )}

{ (activeSection === 'paving') && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue21) === 0 || formData.inputValue21 === 0)}>
          <ResultTableDataOrig>
            Additional hardcore:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
            <Input
              type="number"
              name="inputValue21"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue21}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue21 === 0) {
                  handleChange({ target: { name: 'inputValue21', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            ton
            </ResultTableDataOrig>
            </ResultTableRow>
            )}
            { (activeSection === 'paving') && (
                <ResultTableRow>
                <ResultTableDataOrig>
                  Laying paving on concrete pad{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                <input
                    type="checkbox"
                    checked={checkedItems[49] || false}
                    onChange={(event) => handleCheckboxChange(event, 49)}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
                )}
                { (activeSection === 'paving') && (
                <ResultTableRow>
                <ResultTableDataOrig>
                  Reduce edgings from paving m²{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                <input
                    type="checkbox"
                    checked={checkedItems[50] || false}
                    onChange={(event) => handleCheckboxChange(event, 50)}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
                )}


              <ResultTableRow>
              <ColspanCell colSpan={2}>
          <TitleDiv onClick={() => handleTitleClick('blockpaving')}>Block Paving</TitleDiv>
              </ColspanCell>
              </ResultTableRow>
              
          {activeSection === 'blockpaving' && (
          <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue2) === 0 || formData.inputValue2 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue2}>
            Block paving area:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue2}>
            <Input
              type="number"
              name="inputValue2"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue2}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue2 === 0) {
                  handleChange({ target: { name: 'inputValue2', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
              />{" "}m²
            </ResultTableDataOrig>
            </ResultTableRow>
            )}

            {activeSection === 'blockpaving' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue23) === 0 || formData.inputValue23 === 0)}>
          <ResultTableDataOrig>
            Block paving price:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
            <Input
              type="number"
              name="inputValue23"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue23}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue23 === 0) {
                  handleChange({ target: { name: 'inputValue23', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            per m²
          </ResultTableDataOrig>
          </ResultTableRow>
          )}

          {activeSection === 'blockpaving' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValueSetts) === 0 || formData.inputValueSetts === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValueSetts}>
            Setts area:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValueSetts}>
            <Input
              type="number"
              name="inputValueSetts"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValueSetts}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValueSetts === 0) {
                  handleChange({ target: { name: 'inputValueSetts', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            m²
          </ResultTableDataOrig>
          </ResultTableRow>
          )}

          { (activeSection === 'blockpaving') && (
          <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue3) === 0 || formData.inputValue3 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue3}>
            Linear edgings:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue3}>
            <Input
              type="number"
              name="inputValue3"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue3}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue3 === 0) {
                  handleChange({ target: { name: 'inputValue3', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            m
            </ResultTableDataOrig>
            </ResultTableRow>
            )}

{ (activeSection === 'blockpaving') && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue48) === 0 || formData.inputValue48 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue48}>
            Curb steps:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue48}>
            <Input
              type="number"
              name="inputValue48"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue48}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue48 === 0) {
                  handleChange({ target: { name: 'inputValue48', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "} 
            </ResultTableDataOrig>
            </ResultTableRow>
)}
{ (activeSection === 'blockpaving') && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue49) === 0 || formData.inputValue49 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue49}>
            Step width:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue49}>
            <Input
              type="number"
              name="inputValue49"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue49}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue49 === 0) {
                  handleChange({ target: { name: 'inputValue49', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            m
            </ResultTableDataOrig>
            </ResultTableRow>
)}

             { (activeSection === 'blockpaving') && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue4) === 0 || formData.inputValue4 === 0)}>
          <ResultTableDataOrig>
            Additional waste:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
            <Input
              type="number"
              name="inputValue4"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue4}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue4 === 0) {
                  handleChange({ target: { name: 'inputValue4', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            ton
            </ResultTableDataOrig>
            </ResultTableRow>
            )}

             { (activeSection === 'blockpaving') && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue21) === 0 || formData.inputValue21 === 0)}>
          <ResultTableDataOrig>
            Additional hardcore:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
            <Input
              type="number"
              name="inputValue21"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue21}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue21 === 0) {
                  handleChange({ target: { name: 'inputValue21', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            ton
            </ResultTableDataOrig>
            </ResultTableRow>
            )}

            <ResultTableRow>
              <ColspanCell colSpan={2}>
          <TitleDiv onClick={() => handleTitleClick('fencing')}>Fencing</TitleDiv>
          </ColspanCell>
          </ResultTableRow>
          {activeSection === 'fencing' && (

            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue5) === 0 || formData.inputValue5 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue5}>
            Fencing length:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue5}>
            <Input
              type="number"
              name="inputValue5"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue5}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue5 === 0) {
                  handleChange({ target: { name: 'inputValue5', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            m
            </ResultTableDataOrig>
            </ResultTableRow>
            )}
{activeSection === 'fencing' && (
  <ResultTableRow>
    <ResultTableDataOrig>
      Fencing Material
    </ResultTableDataOrig>
    <Dropdown>
      
        <DropdownToggle variant="secondary" >
            {selectedMaterial || 'Select Fencing Material'}
        </DropdownToggle>

        <Dropdown.Menu>
            <Dropdown.Item
                key="unselect"
                onClick={() => handleFenceMaterialChange('')}
            >
                Unselect Material
            </Dropdown.Item>
            {settingsData.fencingMaterials?.map((material, index) => (
                <Dropdown.Item
                    key={index}
                    onClick={() => handleFenceMaterialChange(material.name)}
                >
                    {material.name}
                </Dropdown.Item>
            ))}
        </Dropdown.Menu>
    </Dropdown>
    </ResultTableRow>
)}
{activeSection === 'fencing' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue42) === 0 || formData.inputValue42 === 0)}>
          <ResultTableDataOrig>
            Cost of timber work:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
            <Input
              type="number"
              name="inputValue42"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue42}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue42 === 0) {
                  handleChange({ target: { name: 'inputValue42', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            </ResultTableDataOrig>
            </ResultTableRow>
)}

              <ResultTableRow>
              <ColspanCell colSpan={2}>
            <TitleDiv onClick={() => handleTitleClick('walling')}>Walling</TitleDiv>
            </ColspanCell>
            </ResultTableRow>
{activeSection === 'walling' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue7) === 0 || formData.inputValue7 === 0)}>
            <ResultTableDataOrig updated={updatedFields.inputValue7}>
              Wall length:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue7}>
              <Input
                type="number"
                name="inputValue7"
                disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                title={!selectedProject ? 'Create new project to use' : undefined}
                value={formData.inputValue7}
                onChange={handleChange}
                onClick={() => {
                  if (formData.inputValue7 === 0) {
                    handleChange({ target: { name: 'inputValue7', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
              />{" "}
              m
            </ResultTableDataOrig>
            </ResultTableRow>
)}
           
           {activeSection === 'walling' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue8) === 0 || formData.inputValue8 === 0)}>
            <ResultTableDataOrig>
              Wall height:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
              <Input
                type="number"
                name="inputValue8"
                disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                title={!selectedProject ? 'Create new project to use' : undefined}
                value={formData.inputValue8}
                onChange={handleChange}
                onClick={() => {
                  if (formData.inputValue8 === 0) {
                    handleChange({ target: { name: 'inputValue8', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
              />{" "}
              m
            </ResultTableDataOrig>
            </ResultTableRow>
)}
{activeSection === 'walling' && (
                <ResultTableRow>
                  <ResultTableDataOrig>
                    Wall material
                  </ResultTableDataOrig>
                  <ResultTableDataOrig>
                  <MaterialDropdown>
        <DropdownToggle variant="secondary" id="materialDropdown">
          {selectedWallMaterial || 'Material'}
        </DropdownToggle>
        <Dropdown.Menu>
          {materialOptions.map((option, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleMaterialChange(option.value)}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </MaterialDropdown>
                  </ResultTableDataOrig>
                </ResultTableRow>
                )}
                {activeSection === 'walling' && (
                <ResultTableRow>
                <ResultTableDataOrig>
                  Double skin / Block flat{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                <input
                    type="checkbox"
                    checked={checkedItems[48] || false}
                    onChange={(event) => handleCheckboxChange(event, 48)}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
                )}
                {activeSection === 'walling' && (
                <ResultTableRow>
                <ResultTableDataOrig>
                  Add footing{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                <input
                    type="checkbox"
                    checked={checkedItems[51] || false}
                    onChange={(event) => handleCheckboxChange(event, 51)}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
                )}
              <ResultTableRow>
              <ColspanCell colSpan={2}>
            <TitleDiv onClick={() => handleTitleClick('concrete')}>Concrete</TitleDiv>
            </ColspanCell>
            </ResultTableRow>

{activeSection === 'concrete' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue47) === 0 || formData.inputValue47 === 0)}>
            <ResultTableDataOrig updated={updatedFields.inputValue47}>
              Concrete pad:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue47}>
              <Input
                type="number"
                name="inputValue47"
                disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                title={!selectedProject ? 'Create new project to use' : undefined}
                value={formData.inputValue47}
                onChange={handleChange}
                onClick={() => {
                  if (formData.inputValue47 === 0) {
                    handleChange({ target: { name: 'inputValue47', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
              />{" "}
              m²
            </ResultTableDataOrig>
            </ResultTableRow>
)}

<ResultTableRow>
              <ColspanCell colSpan={2}>
            <TitleDiv onClick={() => handleTitleClick('asphalt')}>Asphalt</TitleDiv>
            </ColspanCell>
            </ResultTableRow>

{activeSection === 'asphalt' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue56) === 0 || formData.inputValue56 === 0)}>
            <ResultTableDataOrig updated={updatedFields.inputValue56}>
              Asphalt area:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue56}>
              <Input
                type="number"
                name="inputValue56"
                disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                title={!selectedProject ? 'Create new project to use' : undefined}
                value={formData.inputValue56}
                onChange={handleChange}
                onClick={() => {
                  if (formData.inputValue56 === 0) {
                    handleChange({ target: { name: 'inputValue56', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
              />{" "}
              m³
            </ResultTableDataOrig>
            </ResultTableRow>
)}

{ (activeSection === 'asphalt') && (
          <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue3) === 0 || formData.inputValue3 === 0)}>
          <ResultTableDataOrig updated={updatedFields.inputValue3}>
            Linear edgings:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue3}>
            <Input
              type="number"
              name="inputValue3"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue3}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue3 === 0) {
                  handleChange({ target: { name: 'inputValue3', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            m
            </ResultTableDataOrig>
            </ResultTableRow>
            )}

<ResultTableRow>
              <ColspanCell colSpan={2}>
<TitleDiv onClick={() => handleTitleClick('decking')}>Decking</TitleDiv>
</ColspanCell>
</ResultTableRow>
{activeSection === 'decking' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue6) === 0 || formData.inputValue6 === 0)}>
            <ResultTableDataOrig updated={updatedFields.inputValue6}>
              Decking width:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue6}>
              <Input
                type="number"
                name="inputValue6"
                disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                title={!selectedProject ? 'Create new project to use' : undefined}
                value={formData.inputValue6}
                onChange={handleChange}
                onClick={() => {
                  if (formData.inputValue6 === 0) {
                    handleChange({ target: { name: 'inputValue6', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
              />{" "}
              m
            </ResultTableDataOrig>
            </ResultTableRow>
)}

{activeSection === 'decking' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue46) === 0 || formData.inputValue46 === 0)}>
            <ResultTableDataOrig updated={updatedFields.inputValue46}>
              Decking length:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue46}>
              <Input
                type="number"
                name="inputValue46"
                disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                title={!selectedProject ? 'Create new project to use' : undefined}
                value={formData.inputValue46}
                onChange={handleChange}
                onClick={() => {
                  if (formData.inputValue46 === 0) {
                    handleChange({ target: { name: 'inputValue46', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
              />{" "}
              m
            </ResultTableDataOrig>
            </ResultTableRow>
            )}

{activeSection === 'decking' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue55) === 0 || formData.inputValue55 === 0)}>
            <ResultTableDataOrig updated={updatedFields.inputValue55}>
              Decking area:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue55}>
              <Input
                type="number"
                name="inputValue55"
                disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                title={!selectedProject ? 'Create new project to use' : undefined}
                value={formData.inputValue55}
                onChange={handleChange}
                onClick={() => {
                  if (formData.inputValue55 === 0) {
                    handleChange({ target: { name: 'inputValue55', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
              />{" "}
              m²
            </ResultTableDataOrig>
            </ResultTableRow>
            )}

            {activeSection === 'decking' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue44) === 0 || formData.inputValue44 === 0)}>
            <ResultTableDataOrig>
              Decking height from ground:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
              <Input
                type="number"
                name="inputValue44"
                disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                title={!selectedProject ? 'Create new project to use' : undefined}
                value={formData.inputValue44}
                onChange={handleChange}
                onClick={() => {
                  if (formData.inputValue44 === 0) {
                    handleChange({ target: { name: 'inputValue44', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
              />{" "}
              m
            </ResultTableDataOrig>
            </ResultTableRow>
)}
{activeSection === 'decking' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue45) === 0 || formData.inputValue45 === 0)}>
            <ResultTableDataOrig>
              Exposed frame length:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
              <Input
                type="number"
                name="inputValue45"
                disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                title={!selectedProject ? 'Create new project to use' : undefined}
                value={formData.inputValue45}
                onChange={handleChange}
                onClick={() => {
                  if (formData.inputValue45 === 0) {
                    handleChange({ target: { name: 'inputValue45', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
              />{" "}
              m
            </ResultTableDataOrig>
            </ResultTableRow>
)}
<ResultTableRow>
              <ColspanCell colSpan={2}>
<TitleDiv onClick={() => handleTitleClick('timber')}>Timber</TitleDiv>
</ColspanCell>
</ResultTableRow>
{activeSection === 'timber' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue16) === 0 || formData.inputValue16 === 0)}>
            <ResultTableDataOrig>
                Sleepers in height:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
                <Input
                  type="number"
                  name="inputValue16"
                  disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                  title={!selectedProject ? 'Create new project to use' : undefined}
                  value={formData.inputValue16}
                  onChange={handleChange}
                  onClick={() => {
                    if (formData.inputValue16 === 0) {
                      handleChange({ target: { name: 'inputValue16', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                    }
                  }}
                />{" "}
                units
            </ResultTableDataOrig>
            </ResultTableRow>
)}
{activeSection === 'timber' && (
            <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue17) === 0 || formData.inputValue17 === 0)}>
            <ResultTableDataOrig updated={updatedFields.inputValue17}>
                Sleepers in length:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig updated={updatedFields.inputValue17}>
                <Input
                  type="number"
                  name="inputValue17"
                  disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                  title={!selectedProject ? 'Create new project to use' : undefined}
                  value={formData.inputValue17}
                  onChange={handleChange}
                  onClick={() => {
                    if (formData.inputValue17 === 0) {
                      handleChange({ target: { name: 'inputValue17', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                    }
                  }}
                />{" "}
                m
              </ResultTableDataOrig>
              </ResultTableRow>
)}

{activeSection === 'timber' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue42) === 0 || formData.inputValue42 === 0)}>
          <ResultTableDataOrig>
            Cost of timber work:{" "}
            </ResultTableDataOrig>
            <ResultTableDataOrig>
            <Input
              type="number"
              name="inputValue42"
              disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
              title={!selectedProject ? 'Create new project to use' : undefined}
              value={formData.inputValue42}
              onChange={handleChange}
              onClick={() => {
                if (formData.inputValue42 === 0) {
                  handleChange({ target: { name: 'inputValue42', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
            />{" "}
            </ResultTableDataOrig>
            </ResultTableRow>
)}

<ResultTableRow>
              <ColspanCell colSpan={2}>
<TitleDiv onClick={() => handleTitleClick('drainage')}>Drainage</TitleDiv>
</ColspanCell>
</ResultTableRow>
{activeSection === 'drainage' && (
              <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue9) === 0 || formData.inputValue9 === 0)}>
              <ResultTableDataOrig updated={updatedFields.inputValue9}>
                Channel drains:{" "}
              </ResultTableDataOrig>
              <ResultTableDataOrig updated={updatedFields.inputValue9}>
                <Input
                  type="number"
                  name="inputValue9"
                  disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                  title={!selectedProject ? 'Create new project to use' : undefined}
                  value={formData.inputValue9}
                  onChange={handleChange}
                  onClick={() => {
                    if (formData.inputValue9 === 0) {
                      handleChange({ target: { name: 'inputValue9', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                    }
                  }}
                />{" "}
                m
              </ResultTableDataOrig>
              </ResultTableRow>
)}
{activeSection === 'drainage' && (
              <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue10) === 0 || formData.inputValue10 === 0)}>
              <ResultTableDataOrig updated={updatedFields.inputValue10}>
                Drainage pipe:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig updated={updatedFields.inputValue10}>
                  <Input
                    type="number"
                    name="inputValue10"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError}
                    title={!selectedProject ? 'Create new project to use' : undefined} 
                    value={formData.inputValue10}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue10 === 0) {
                        handleChange({ target: { name: 'inputValue10', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />{" "}
                  m
                </ResultTableDataOrig>
                </ResultTableRow>
)}
{activeSection === 'drainage' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue11) === 0 || formData.inputValue11 === 0)}>
              <ResultTableDataOrig updated={updatedFields.inputValue11}>
                  Gully pot:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig updated={updatedFields.inputValue11}>
                  <Input
                    type="number"
                    name="inputValue11"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue11}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue11 === 0) {
                        handleChange({ target: { name: 'inputValue11', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  /> units
                </ResultTableDataOrig>
                </ResultTableRow>
)}
{activeSection === 'drainage' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue12) === 0 || formData.inputValue12 === 0)}>
                <ResultTableDataOrig>
                  Fittings:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue12"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue12}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue12 === 0) {
                        handleChange({ target: { name: 'inputValue12', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  /> units
                </ResultTableDataOrig>
                </ResultTableRow>
)}
{activeSection === 'drainage' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue13) === 0 || formData.inputValue13 === 0)}>
                <ResultTableDataOrig updated={updatedFields.inputValue13}>
                  Manhole cover:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig updated={updatedFields.inputValue13}>
                  <Input
                    type="number"
                    name="inputValue13"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue13}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue13 === 0) {
                        handleChange({ target: { name: 'inputValue13', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  /> units
                </ResultTableDataOrig>
                </ResultTableRow>
)}
<ResultTableRow>
              <ColspanCell colSpan={2}>
<TitleDiv onClick={() => handleTitleClick('decorative')}>Decorative</TitleDiv>
</ColspanCell>
</ResultTableRow>
{activeSection === 'decorative' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue18) === 0 || formData.inputValue18 === 0)}>
                <ResultTableDataOrig updated={updatedFields.inputValue18}>
                  Gravel area:{" "}
                  </ResultTableDataOrig>
                <ResultTableDataOrig updated={updatedFields.inputValue18}>
                  <Input
                    type="number"
                    name="inputValue18"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue18}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue18 === 0) {
                        handleChange({ target: { name: 'inputValue18', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                    />m²
                </ResultTableDataOrig>
                </ResultTableRow>
)}
{activeSection === 'decorative' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue50) === 0 || formData.inputValue50 === 0)}>
                <ResultTableDataOrig updated={updatedFields.inputValue50}>
                  Bark area:{" "}
                  </ResultTableDataOrig>
                <ResultTableDataOrig updated={updatedFields.inputValue50}>
                  <Input
                    type="number"
                    name="inputValue50"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue50}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue50 === 0) {
                        handleChange({ target: { name: 'inputValue50', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                    />m²
                </ResultTableDataOrig>
                </ResultTableRow>
)}
<ResultTableRow>
              <ColspanCell colSpan={2}>
<TitleDiv onClick={() => handleTitleClick('turfing')}>Turfing</TitleDiv>
</ColspanCell>
</ResultTableRow>
{activeSection === 'turfing' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue51) === 0 || formData.inputValue51 === 0)}>
                <ResultTableDataOrig updated={updatedFields.inputValue51}>
                  Artificial turf area:{" "}
                  </ResultTableDataOrig>
                <ResultTableDataOrig updated={updatedFields.inputValue51}>
                  <Input
                    type="number"
                    name="inputValue51"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue51}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue51 === 0) {
                        handleChange({ target: { name: 'inputValue51', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                    />m²
                </ResultTableDataOrig>
                </ResultTableRow>
                )}
                {activeSection === 'turfing' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue19) === 0 || formData.inputValue19 === 0)}>
                <ResultTableDataOrig updated={updatedFields.inputValue19}>
                  Turf area:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig updated={updatedFields.inputValue19}>
                  <Input
                    type="number"
                    name="inputValue19"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue19}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue19 === 0) {
                        handleChange({ target: { name: 'inputValue19', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                    />m²
                </ResultTableDataOrig>
                </ResultTableRow>
 )}
 {activeSection === 'turfing' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue20) === 0 || formData.inputValue20 === 0)}>
                <ResultTableDataOrig>
                  Top soil depth:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue20"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue20}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue20 === 0) {
                        handleChange({ target: { name: 'inputValue20', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />mm
                </ResultTableDataOrig>
                </ResultTableRow>
)}
<ResultTableRow>
              <ColspanCell colSpan={2}>
<TitleDiv onClick={() => handleTitleClick('machinehire')}>Machine hire</TitleDiv>
</ColspanCell>
</ResultTableRow>
{activeSection === 'machinehire' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue14) === 0 || formData.inputValue14 === 0)}>
                <ResultTableDataOrig>
                  Digger:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue14"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue14}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue14 === 0) {
                        handleChange({ target: { name: 'inputValue14', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />days
                </ResultTableDataOrig>
                </ResultTableRow>
)}
{activeSection === 'machinehire' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue15) === 0 || formData.inputValue15 === 0)}>
                <ResultTableDataOrig>
                  Dumper:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue15"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue15}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue15 === 0) {
                        handleChange({ target: { name: 'inputValue15', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />days
                </ResultTableDataOrig>
                </ResultTableRow>
)}
{activeSection === 'machinehire' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue52) === 0 || formData.inputValue52 === 0)}>
                <ResultTableDataOrig>
                  Cost of other hire:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue52"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue52}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue52 === 0) {
                        handleChange({ target: { name: 'inputValue52', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
)}
<ResultTableRow>
              <ColspanCell colSpan={2}>
<TitleDiv onClick={() => handleTitleClick('other')}>Other</TitleDiv>
</ColspanCell>
</ResultTableRow>

{activeSection === 'other' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue37) === 0 || formData.inputValue37 === 0)}>
                <ResultTableDataOrig>
                  Cost of electrical work:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue37"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue37}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue37 === 0) {
                        handleChange({ target: { name: 'inputValue37', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
)}
{activeSection === 'other' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue38) === 0 || formData.inputValue38 === 0)}>
                <ResultTableDataOrig>
                  Cost of water feature:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue38"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue38}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue38 === 0) {
                        handleChange({ target: { name: 'inputValue38', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
)}
{activeSection === 'other' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue39) === 0 || formData.inputValue39 === 0)}>
                <ResultTableDataOrig>
                  Cost of expendables:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue39"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue39}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue39 === 0) {
                        handleChange({ target: { name: 'inputValue39', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
                )}

{activeSection === 'other' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue54) === 0 || formData.inputValue54 === 0)}>
                <ResultTableDataOrig>
                  Cost of fuel:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue54"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue54}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue54 === 0) {
                        handleChange({ target: { name: 'inputValue54', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
                )}

{activeSection === 'other' && (
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue53) === 0 || formData.inputValue53 === 0)}>
                <ResultTableDataOrig>
                  Skips:{" "}
                </ResultTableDataOrig>
                <ResultTableDataOrig>
                  <Input
                    type="number"
                    name="inputValue53"
                    disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                    title={!selectedProject ? 'Create new project to use' : undefined}
                    value={formData.inputValue53}
                    onChange={handleChange}
                    onClick={() => {
                      if (formData.inputValue53 === 0) {
                        handleChange({ target: { name: 'inputValue53', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />
                </ResultTableDataOrig>
                </ResultTableRow>
                )}

                </tbody>         
      
          </ResultTable>
          </ValueContainer>
          

          <LabourContainer className="labour-input" isMinimized={isLabourMinimized} isProjectSelected={!!selectedProject}>
          
            <ResultTable>
            <thead>
              <tr>
              <ResultTableHeader style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}>{isLabourMinimized ? "Labour" : "Stage"}</ResultTableHeader>
                <ResultTableHeader style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}>
                  {isLabourMinimized ? " " : "Completion"}
                  <MinimizeButton onClick={toggleLabourMinimized}>
                    {isLabourMinimized ? "+" : "-"}
                  </MinimizeButton>
                </ResultTableHeader>
              </tr>
            </thead>
              <tbody>
                
                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue26) === 0 || formData.inputValue26 === 0)}>
                <ResultTableData>
                  Excavation
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue26"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue26}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue26 === 0) {
                          handleChange({ target: { name: 'inputValue26', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                   Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue36) === 0 || formData.inputValue36 === 0)}>
                <ResultTableData>
                  Sub base
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue36"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue36}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue36 === 0) {
                          handleChange({ target: { name: 'inputValue36', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                   Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow  hidden={toggleUnusedValues && (Number(formData.inputValue27) === 0 || formData.inputValue27 === 0)}>
                <ResultTableData>
                  Edgings
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue27"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue27}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue27 === 0) {
                          handleChange({ target: { name: 'inputValue27', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow  hidden={toggleUnusedValues && (Number(formData.inputValue28) === 0 || formData.inputValue28 === 0)}>
                <ResultTableData>
                  Paving
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue28"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue28}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue28 === 0) {
                          handleChange({ target: { name: 'inputValue28', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow  hidden={toggleUnusedValues && (Number(formData.inputValue29) === 0 || formData.inputValue29 === 0)}>
                <ResultTableData>
                  Block paving
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue29"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue29}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue29 === 0) {
                          handleChange({ target: { name: 'inputValue29', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue57) === 0 || formData.inputValue57 === 0)}>
                <ResultTableData>
                  Asphalt
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue57"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue57}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue57 === 0) {
                          handleChange({ target: { name: 'inputValue57', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue30) === 0 || formData.inputValue30 === 0)}>
                <ResultTableData>
                  Walling
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue30"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue30}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue30 === 0) {
                          handleChange({ target: { name: 'inputValue30', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow  hidden={toggleUnusedValues && (Number(formData.inputValue31) === 0 || formData.inputValue31 === 0)}>
                <ResultTableData>
                  Pointing
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue31"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue31}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue31 === 0) {
                          handleChange({ target: { name: 'inputValue31', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue32) === 0 || formData.inputValue32 === 0)}>
                <ResultTableData>
                  Fencing
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue32"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue32}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue32 === 0) {
                          handleChange({ target: { name: 'inputValue32', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow  hidden={toggleUnusedValues && (Number(formData.inputValue43) === 0 || formData.inputValue43 === 0)}>
                <ResultTableData>
                  Timber
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue43"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue43}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue43 === 0) {
                          handleChange({ target: { name: 'inputValue43', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue40) === 0 || formData.inputValue40 === 0)}>
                <ResultTableData>
                  Decking
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue40"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue40}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue40 === 0) {
                          handleChange({ target: { name: 'inputValue40', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue41) === 0 || formData.inputValue41 === 0)}>
                <ResultTableData>
                  Turfing
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue41"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue41}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue41 === 0) {
                          handleChange({ target: { name: 'inputValue41', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow  hidden={toggleUnusedValues && (Number(formData.inputValue58) === 0 || formData.inputValue58 === 0)}>
                <ResultTableData>
                  Gravel
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue58"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue58}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue58 === 0) {
                          handleChange({ target: { name: 'inputValue58', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue34) === 0 || formData.inputValue34 === 0)}>
                <ResultTableData>
                  Additional days
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue34"
                      disabled={!selectedProject || isMaterialDataError || isSettingsDataError} 
                      title={!selectedProject ? 'Create new project to use' : undefined}
                      value={formData.inputValue34}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue34 === 0) {
                          handleChange({ target: { name: 'inputValue34', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Days
                  </ResultTableData>
                </ResultTableRow>

                </tbody>
                <tbody>
                <TableSeparatorRow></TableSeparatorRow>
                </tbody>
                <tbody>
                
                <ResultTableRow>
                <ResultTableData>
                  Labour time
                  </ResultTableData>
                  <ResultTableData>
                   {labourTime} Days
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow>
                <ResultTableData>
                  Labour cost
                  </ResultTableData>
                  <ResultTableData>
                   <Input
                      type="number"
                      name="inputValue24"
                      value={formData.inputValue24}
                      onChange={handleChange}
                      onClick={() => {
                        if (formData.inputValue24 === 0) {
                          handleChange({ target: { name: 'inputValue24', value: '' } } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                      />{" "}
                    Per day
                  </ResultTableData>
                </ResultTableRow>

                <ResultTableRow>
                <ResultTableData>
                  Add labour VAT{" "}
                 </ResultTableData>
                <ResultTableData>
                <input
                    type="checkbox"
                    checked={checkedItems[52] || false}
                    onChange={(event) => handleCheckboxChange(event, 52)}
                /> 
                <PriceSpan>
                {checkedItems[52] ? `£${labourVatPrice}` : ""}
                </PriceSpan>

                </ResultTableData>
                </ResultTableRow>

                <ResultTableRow>
                <ResultTableData>
                  Cost of labour
                  </ResultTableData>
                  <ResultTableData>
                   £{checkedItems[52] ? labourAddedVat : labourPrice}
                  </ResultTableData>
                </ResultTableRow>

              </tbody>
            </ResultTable>
          </LabourContainer>

          <ContainerWrapper className="price-input" isMinimized={isResultMinimized} isProjectSelected={!!selectedProject}>
          <ResultContainer isMinimized={isResultMinimized} isProjectSelected={!!selectedProject}>
  <ResultTable ref={table1Ref}>
    <thead>
      <tr>
        <ResultTableHeader style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}>{isResultMinimized ? "Pricing" : "Unuse"}</ResultTableHeader>
        <ResultTableHeader style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}>{isResultMinimized ? "" : "Materials"}</ResultTableHeader>
        <ResultTableHeader style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}>{isResultMinimized ? "" : "Value"}</ResultTableHeader>
        <ResultTableHeader style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}>{isResultMinimized ? "" : "Cost"}<MinimizeButton onClick={toggleResultMinimized}>
          {isResultMinimized ? "+" : "-"}
        </MinimizeButton>
        </ResultTableHeader>
      </tr>
    </thead>
    <tbody>
      
    <ResultTableRow hidden={toggleUnusedValues && (Number(formData.totalWasteCalc) === 0)}>
        <ResultTableData>
        <input
            className="unuse-input"
            type="checkbox"
            checked={checkedItems[0] || false}
            onChange={(event) => handleCheckboxChange(event, 0)}
            style={{marginRight: '3vh'}}
          />
        </ResultTableData>
        <ResultTableData>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {wastePrice > 0 && (
              <div onClick={toggleWasteDetails} style={{ cursor: 'pointer', marginRight: '5px' }}>
                {showWasteDetails ? '▼' : '▶'} {/* This is your arrow */}
              </div>
            )}
            <span>Waste</span>
          </div>
              </ResultTableData>
              <ResultTableDataText>
              <input
              className="lock-input"
          type="checkbox"
          name="totalWasteCalc"
          checked={lockedFields["totalWasteCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[0] ? "0 " : 
        <InputResult
            className="edit-input"
            type="number"
            name="totalWasteCalc"
            value={formData.totalWasteCalc === undefined || formData.totalWasteCalc === null ? "0.00" : formData.totalWasteCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            ton</ResultTableDataText>
          <ResultTableData>
              £{checkedItems[0] ? 0.00 : wastePrice}
          </ResultTableData>
      </ResultTableRow>
      {showWasteDetails && (
  <>
  {Number(formData.pavingWasteCalc) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Waste of paving: {Number(formData.pavingWasteCalc)} ton
      </ResultTableData>
    </ResultTableRow>
  )}
  {Number(formData.bPavingWasteCalc) > 0 &&(
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Waste of block paving: {Number(formData.bPavingWasteCalc)} ton
      </ResultTableData>
    </ResultTableRow>
    )}
    {Number(formData.settsWaste) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Waste of setts: {Number(formData.settsWaste)} ton
      </ResultTableData>
    </ResultTableRow>
    )}
    {Number(formData.footingWaste) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Waste of footing: {Number(formData.footingWaste)} ton
      </ResultTableData>
    </ResultTableRow>
    )}
    {Number(formData.edgingWasteCalc) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Waste of edgings: {Number(formData.edgingWasteCalc)} ton
      </ResultTableData>
    </ResultTableRow>
    )}
    {Number(formData.asphaltWaste) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Waste of asphalt: {Number(formData.asphaltWaste)} ton
      </ResultTableData>
    </ResultTableRow>
    )}
    {Number(formData.inputValue4) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Additional Waste: {Number(formData.inputValue4)}
      </ResultTableData>
    </ResultTableRow>
    )}
  </>
)}
      
      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.hardcoreCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[1] || false}
            onChange={(event) => handleCheckboxChange(event, 1)}
          />
        </ResultTableData>
        <ResultTableData>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {hardcorePrice > 0 && (
              <div onClick={toggleHardcoreDetails} style={{ cursor: 'pointer', marginRight: '5px' }}>
                {showHardcoreDetails ? '▼' : '▶'} {/* This is your arrow */}
              </div>
            )}
            <span>Hardcore</span>
          </div>
          </ResultTableData>
          <ResultTableDataText>
          <input
          type="checkbox"
          name="hardcoreCalc"
          checked={lockedFields["hardcoreCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[1] ? "0 " : 
        <InputResult
            type="number"
            name="hardcoreCalc"
            value={formData.hardcoreCalc === undefined || formData.hardcoreCalc === null ? "0.00" : formData.hardcoreCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            ton</ResultTableDataText>
        <ResultTableData>
          £{checkedItems[1] ? 0.00 : hardcorePrice}
        </ResultTableData>
      </ResultTableRow>
      {showHardcoreDetails && (
  <>
  {Number(formData.pavingHardcoreCalc) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Hardcore of paving: {Number(formData.pavingHardcoreCalc)} ton
      </ResultTableData>
    </ResultTableRow>
  )}

  {Number(formData.bPavingHardcoreCalc) > 0 &&(
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Hardcore of block paving: {Number(formData.bPavingHardcoreCalc)} ton
      </ResultTableData>
    </ResultTableRow>
    )}

{Number(formData.asphaltHardcore) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Hardcore of asphalt: {Number(formData.asphaltHardcore)} ton
      </ResultTableData>
    </ResultTableRow>
  )}

    {Number(formData.edgingHardcore) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Hardcore of edgings: {Number(formData.edgingHardcore)} ton
      </ResultTableData>
    </ResultTableRow>
  )}

{Number(formData.settsHardcore) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Hardcore of setts: {Number(formData.settsHardcore)} ton
      </ResultTableData>
    </ResultTableRow>
  )}

    {Number(formData.concretePadHardcore) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Hardcore of concrete pad: {Number(formData.concretePadHardcore)} ton
      </ResultTableData>
    </ResultTableRow>
    )}

    {Number(formData.inputValue21) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Additional hardcore: {Number(formData.inputValue21)}
      </ResultTableData>
    </ResultTableRow>
    )}
  </>
)}

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.pavingCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[2] || false}
            onChange={(event) => handleCheckboxChange(event, 2)}
          />
        </ResultTableData>
        <ResultTableData>Paving</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="pavingCalc"
          checked={lockedFields["pavingCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[2] ? "0 " : 
        <InputResult
            type="number"
            name="pavingCalc"
            value={formData.pavingCalc === undefined || formData.pavingCalc === null || Number(formData.pavingCalc) === 0
              ? "0.00"
              : Number(formData.pavingCalc).toString()}
              onBlur={handleLockBlur}
              onFocus={handleFocus}
            onChange={handleChange}
          />}
            m²</ResultTableDataText>
        <ResultTableData>
          £{checkedItems[2] ? 0.00 : Number(pavingPrice) || 0}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.edgingCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[3] || false}
            onChange={(event) => handleCheckboxChange(event, 3)}
          />
        </ResultTableData>
        <ResultTableData>Edgings</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="edgingCalc"
          checked={lockedFields["edgingCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[3] ? "0 " : 
        <InputResult
            type="number"
            name="edgingCalc"
            value={formData.edgingCalc === undefined || formData.edgingCalc === null ? "0.00" : formData.edgingCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            m²</ResultTableDataText>
        <ResultTableData>
          £{checkedItems[3] ? 0.00 : edgingPrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.blockPavingCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[4] || false}
            onChange={(event) => handleCheckboxChange(event, 4)}
          />
        </ResultTableData>
        <ResultTableData>Block paving</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="blockPavingCalc"
          checked={lockedFields["blockPavingCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[4] ? "0 " : 
        <InputResult
            type="number"
            name="blockPavingCalc"
            value={formData.blockPavingCalc === undefined || formData.blockPavingCalc === null || Number(formData.blockPavingCalc) === 0
              ? "0.00"
              : Number(formData.blockPavingCalc).toString()}            
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            m²</ResultTableDataText>
        <ResultTableData>
        £{checkedItems[4] ? 0.00 : bPavingPrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValueSetts) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[43] || false}
            onChange={(event) => handleCheckboxChange(event, 43)}
          />
        </ResultTableData>
        <ResultTableData>Setts</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="settsCalc"
          checked={lockedFields["settsCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[43] ? "0 " : 
        <InputResult
            type="number"
            name="settsCalc"
            value={formData.settsCalc === undefined || formData.settsCalc === null || Number(formData.settsCalc) === 0 ? "0.00" : formData.settsCalc.toString()}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            m²</ResultTableDataText>
        <ResultTableData>
        £{checkedItems[43] ? 0.00 : settsPrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.blockPavingScreed) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[5] || false}
            onChange={(event) => handleCheckboxChange(event, 5)}
          />
        </ResultTableData>
        <ResultTableData><div style={{ display: 'flex', alignItems: 'center' }}>
            {blockPavingScreedPrice > 0 && (
              <div onClick={toggleScreedDetails} style={{ cursor: 'pointer', marginRight: '5px' }}>
                {showScreedDetails ? '▼' : '▶'} {/* This is your arrow */}
              </div>
            )}
            <span>Screed material</span>
          </div></ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="blockPavingScreed"
          checked={lockedFields["blockPavingScreed"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[5] ? "0 " : 
        <InputResult
            type="number"
            name="blockPavingScreed"
            value={formData.blockPavingScreed === undefined || formData.blockPavingScreed === null ? "0.00" : formData.blockPavingScreed}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            ton</ResultTableDataText>
        <ResultTableData>
        £{checkedItems[5] ? 0.00 : blockPavingScreedPrice}
          </ResultTableData>
      </ResultTableRow>
      {showScreedDetails && (
  <>
  {Number(formData.pavingScreedCalc) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Screed for block paving: {Number(formData.pavingScreedCalc)} ton
      </ResultTableData>
    </ResultTableRow>
  )}
  {Number(formData.artificialTurfScreed) > 0 &&(
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Screed for artificial turf: {Number(formData.artificialTurfScreed)} ton
      </ResultTableData>
    </ResultTableRow>
    )}
  </>
)}
      
      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.kilnSand) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[44] || false}
            onChange={(event) => handleCheckboxChange(event, 44)}
          />
        </ResultTableData>
        <ResultTableData>Kiln sand</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="kilnSand"
          checked={lockedFields["kilnSand"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[44] ? "0 " : 
        <InputResult
            type="number"
            name="kilnSand"
            value={formData.kilnSand === undefined || formData.kilnSand === null ? "0.00" : formData.kilnSand}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            bags</ResultTableDataText>
        <ResultTableData>
        £{checkedItems[44] ? 0.00 : KilnSandPrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.membraneCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[6] || false}
            onChange={(event) => handleCheckboxChange(event, 6)}
          />
        </ResultTableData>
        <ResultTableData><div style={{ display: 'flex', alignItems: 'center' }}>
            {membranePrice > 0 && (
              <div onClick={toggleMembraneDetails} style={{ cursor: 'pointer', marginRight: '5px' }}>
                {showMembraneDetails ? '▼' : '▶'} {/* This is your arrow */}
              </div>
            )}
            <span>Membrane</span>
          </div></ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="membraneCalc"
          checked={lockedFields["membraneCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[6] ? "0 " : 
        <InputResult
            type="number"
            name="membraneCalc"
            value={formData.membraneCalc === undefined || formData.membraneCalc === null || Number(formData.membraneCalc) === 0
              ? "0.00"
              : Number(formData.membraneCalc).toString()}
              onBlur={handleLockBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />}
            m²</ResultTableDataText>
        <ResultTableData>
          £{checkedItems[6] ? 0.00 :membranePrice}
          </ResultTableData>
      </ResultTableRow>
      {showMembraneDetails && (
  <>
  {Number(formData.inputValue1) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Membrane for paving: {Number(formData.inputValue1) + Number(formData.edgingCalc)} m²
      </ResultTableData>
    </ResultTableRow>
  )}
  {Number(formData.inputValue2) > 0 &&(
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Membrane for block paving: {Number(formData.inputValue2)} m²
      </ResultTableData>
    </ResultTableRow>
    )}
    {Number(formData.inputValueSetts) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Membrane for setts: {Number(formData.inputValueSetts)} m²
      </ResultTableData>
    </ResultTableRow>
  )}
  </>
)}

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.adhesiveCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[7] || false}
            onChange={(event) => handleCheckboxChange(event, 7)}
          />
        </ResultTableData>
        <ResultTableData>Primer</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="adhesiveCalc"
          checked={lockedFields["adhesiveCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[7] ? "0 " : 
        <InputResult
            type="number"
            name="adhesiveCalc"
            value={formData.adhesiveCalc === undefined || formData.adhesiveCalc === null || Number(formData.adhesiveCalc) === 0
              ? "0.00"
              : Number(formData.adhesiveCalc).toString()}
              onBlur={handleLockBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />}
            units</ResultTableDataText>
        <ResultTableData>
          £{checkedItems[7] ? 0.00 : adhesivePrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.pointingCalc) === 0)}>
        <ResultTableData>
          <input
            type="checkbox"
            checked={checkedItems[8] || false}
            onChange={(event) => handleCheckboxChange(event, 8)}
          />
        </ResultTableData>
        <ResultTableData><div style={{ display: 'flex', alignItems: 'center' }}>
    {(Number(formData.pointingCalc) > 0 || Number(formData.settsPointingCalc) > 0) && (
      <div onClick={togglePointingDetails} style={{ cursor: 'pointer', marginRight: '5px' }}>
        {showPointingDetails ? '▼' : '▶'} 
      </div>
    )}
    <span>Pointing</span>
  </div></ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="pointingCalc"
          checked={lockedFields["pointingCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[8] ? "0 " :
          <InputResult
            type="number"
            name="pointingCalc"
            value={formData.pointingCalc === undefined || formData.pointingCalc === null || Number(formData.pointingCalc) === 0
              ? "0.00"
              : Number(formData.pointingCalc).toString()}            
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}units
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[8] ? 0.00 : pointingPrice}
        </ResultTableData>
      </ResultTableRow>
      {showPointingDetails && (
  <>
    {Number(formData.pointingCalc) > 0 && (
      <ResultTableRow>
        <ResultTableData colSpan={4}>
          Pointing for paving: {Number(formData.pavingPointing) || ""} units
        </ResultTableData>
      </ResultTableRow>
    )}
    {Number(formData.settsPointingCalc) > 0 && (
      <ResultTableRow>
        <ResultTableData colSpan={4}>
          Pointing for setts: {Number(formData.settsPointingCalc)} units
        </ResultTableData>
      </ResultTableRow>
    )}
  </>
)}

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.curbCalc) === 0)}>
        <ResultTableData>
          <input
            type="checkbox"
            checked={checkedItems[9] || false}
            onChange={(event) => handleCheckboxChange(event, 9)}
          />
        </ResultTableData>
        <ResultTableData>Curbs</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="curbCalc"
          checked={lockedFields["curbCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[9] ? "0 " :
          <InputResult
            type="number"
            name="curbCalc"
            value={formData.curbCalc === undefined || formData.curbCalc === null || Number(formData.curbCalc) === 0
              ? "0.00"
              : Number(formData.curbCalc).toString()}
              onBlur={handleLockBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />}curbs
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[9] ? 0.00 : curbPrice}
        </ResultTableData>
      </ResultTableRow>

      </tbody>
      <tbody>
        <TableSeparatorRow></TableSeparatorRow>
      </tbody>
      <tbody>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.sandCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[10] || false}
            onChange={(event) => handleCheckboxChange(event, 10)}
          />
        </ResultTableData>
        <ResultTableData>Paving sand</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="sandCalc"
          checked={lockedFields["sandCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[10] ? "0 " : 
        <InputResult
            type="number"
            name="sandCalc"
            value={formData.sandCalc === undefined || formData.sandCalc === null ? "0.00" : formData.sandCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            ton</ResultTableDataText>
        <ResultTableData>
          £{checkedItems[10] ? 0.00 : sandPrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(cementPrice) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[11] || false}
            onChange={(event) => handleCheckboxChange(event, 11)}
          /> 
        </ResultTableData>
        <ResultTableData><div style={{ display: 'flex', alignItems: 'center' }}>
    {cementPrice > 0 && (
      <div onClick={toggleCementDetails} style={{ cursor: 'pointer', marginRight: '5px' }}>
        {showCementDetails ? '▼' : '▶'} 
      </div>
    )}
    <span>Cement</span>
  </div></ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="cementCalc"
          checked={lockedFields["cementCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[11] ? "0 " :
          <InputResult
            type="number"
            name="cementCalc"
            value={formData.cementCalc === undefined || formData.cementCalc === null || Number(formData.cementCalc) === 0
              ? "0.00"
              : Number(formData.cementCalc).toString()}
              onBlur={handleLockBlur}
              onFocus={handleFocus}
            onChange={handleChange}
          />}bags
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[11] ? 0.00: cementPrice}
        </ResultTableData>
      </ResultTableRow>
      {showCementDetails && (
  <> {Number(formData.pavingCementBags) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
        Cement for paving: {Number(formData.pavingCementBags)} Bags
      </ResultTableData>
    </ResultTableRow>
    )}
    {Number(formData.settsCementBags) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
        Cement for setts: {Number(formData.settsCementBags)} Bags
      </ResultTableData>
    </ResultTableRow>
    )}
    {Number(formData.footingCementCalc) > 0 && (
    <ResultTableRow>
      <ResultTableData colSpan={4}>
      Cement for footing: {Number(formData.footingCementCalc)} Bags
      </ResultTableData>
    </ResultTableRow>
    )}
    {Number(formData.buildingSandCement) > 0 &&(
    <ResultTableRow>
      <ResultTableData colSpan={4}>
        Cement for walling: {Number(formData.buildingSandCement)} Bags
      </ResultTableData>
    </ResultTableRow>
    )}
  </>
)}

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.ballastCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[12] || false}
            onChange={(event) => handleCheckboxChange(event, 12)}
          />
        </ResultTableData>
        <ResultTableData>Ballast</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="ballastCalc"
          checked={lockedFields["ballastCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[12] ? "0 " :
          <InputResult
            type="number"
            name="ballastCalc"
            value={formData.ballastCalc === undefined || formData.ballastCalc === null ? "0.00" : formData.ballastCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}ton
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[12] ? 0.00 : ballastPrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.buildingSandCalc) === 0)}>
        <ResultTableData>
          <input
            type="checkbox"
            checked={checkedItems[13] || false}
            onChange={(event) => handleCheckboxChange(event, 31)}
          />
        </ResultTableData>
        <ResultTableData>Building sand</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="buildingSandCalc"
          checked={lockedFields["buildingSandCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[13] ? "0 " :
          <InputResult
            type="number"
            name="buildingSandCalc"
            value= {formData.buildingSandCalc === undefined || formData.buildingSandCalc === null ? "0.00" : formData.buildingSandCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}ton
        </ResultTableDataText>
        <ResultTableData>
        £{checkedItems[13] ? 0.00 : buildingSandPrice
    }
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.febCalc) === 0)}>
        <ResultTableData>
          <input
            type="checkbox"
            checked={checkedItems[46] || false}
            onChange={(event) => handleCheckboxChange(event, 46)}
          />
        </ResultTableData>
        <ResultTableData>Plasticiser</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="febCalc"
          checked={lockedFields["febCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[46] ? "0 " :
          <InputResult
            type="number"
            name="febCalc"
            value= {formData.febCalc === undefined || formData.febCalc === null ? "0.00" : formData.febCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}litres
        </ResultTableDataText>
        <ResultTableData>
        £{checkedItems[46] ? 0.00 : plasticiserPrice
    }
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.asphaltCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[47] || false}
            onChange={(event) => handleCheckboxChange(event, 47)}
          />
        </ResultTableData>
        
        <ResultTableData>Asphalt</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="asphaltCalc"
          checked={lockedFields["asphaltCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[47] ? "0 " : 
        <InputResult
            type="number"
            name="asphaltCalc"
            value={formData.asphaltCalc === undefined || formData.asphaltCalc === null ? "0.00" : formData.asphaltCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            m³</ResultTableDataText>
        <ResultTableData>
          £{checkedItems[47] ? 0.00 : asphaltPrice}
          </ResultTableData>
      </ResultTableRow>



        <ResultTableRow hidden={toggleUnusedValues && (Number(formData.concreteCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[14] || false}
            onChange={(event) => handleCheckboxChange(event, 14)}
          />
        </ResultTableData>
        
        <ResultTableData>Concrete</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="concreteCalc"
          checked={lockedFields["concreteCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[14] ? "0 " : 
        <InputResult
            type="number"
            name="concreteCalc"
            value={formData.concreteCalc === undefined || formData.concreteCalc === null ? "0.00" : formData.concreteCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            m³</ResultTableDataText>
        <ResultTableData>
          £{checkedItems[14] ? 0.00 : concretePrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.rebarCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[15] || false}
            onChange={(event) => handleCheckboxChange(event, 15)}
          />
        </ResultTableData>
        <ResultTableData>Rebar sheet</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="rebarCalc"
          checked={lockedFields["rebarCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[15] ? "0 " : 
        <InputResult
            type="number"
            name="rebarCalc"
            value={formData.rebarCalc === undefined || formData.rebarCalc === null ? "0.00" : formData.rebarCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}
            sheets</ResultTableDataText>
        <ResultTableData>
          £{checkedItems[15] ? 0.00 : rebarPrice}
          </ResultTableData>
      </ResultTableRow>

      </tbody>
      <tbody>
        <TableSeparatorRow></TableSeparatorRow>
      </tbody>
      <tbody>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.postCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[16] || false}
            onChange={(event) => handleCheckboxChange(event, 16)}
          />
        </ResultTableData>
        <ResultTableData>Posts</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="postCalc"
          checked={lockedFields["postCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[16] ? "0 " :
          <InputResult
            type="number"
            name="postCalc"
            value={formData.postCalc === undefined || formData.postCalc === null ? "0.00" : formData.postCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}posts 
        </ResultTableDataText>
        <ResultTableData>
            £{checkedItems[16] ? 0.00 : postPrice}
          </ResultTableData>
      </ResultTableRow>

      {!selectedMaterial && (
  <ResultTableRow hidden={toggleUnusedValues && (Number(formData.panelCalc) === 0)}>
    <ResultTableData>
      <input
        type="checkbox"
        checked={checkedItems[17] || false}
        onChange={(event) => handleCheckboxChange(event, 17)}
      />
    </ResultTableData>
    <ResultTableData>Panels</ResultTableData>
    <ResultTableDataText>
      <input
        type="checkbox"
        name="panelCalc"
        checked={lockedFields["panelCalc"] || false}
        onChange={handleLockChange}
      />
      {checkedItems[17] ? "0 " :
        <InputResult
          type="number"
          name="panelCalc"
          value={formData.panelCalc === undefined || formData.panelCalc === null ? "0.00" : formData.panelCalc}
          onChange={handleChange}
          onBlur={handleLockBlur}
          onFocus={handleFocus}
        />} panels 
    </ResultTableDataText>
    <ResultTableData>
      £{checkedItems[17] ? 0.00 : panelPrice}
    </ResultTableData>
  </ResultTableRow>
)}


      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.postcreteCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[18] || false}
            onChange={(event) => handleCheckboxChange(event, 18)}
          />
        </ResultTableData>
        <ResultTableData>Postcrete</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="postcreteCalc"
          checked={lockedFields["postcreteCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[18] ? "0 " :
          <InputResult
            type="number"
            name="postcreteCalc"
            value={formData.postcreteCalc === undefined || formData.postcreteCalc === null ? "0.00" : formData.postcreteCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}bags
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[18] ? 0.00 : postcretePrice}
          </ResultTableData>
      </ResultTableRow>

      {updatedFencingMaterials.map((material, index): any => {
  const materialKey = `materialCalc${index}`;
  const calculatedValue = formData[material.materialName] || 0;
  const materialPrice = Number(material.price) * calculatedValue;

  return (
    <ResultTableRow key={index}>
      <ResultTableData>
        <input
          type="checkbox"
          checked={checkedItems[53 + index] || false}
          onChange={(event) => handleCheckboxChange(event, 53 + index)}
        />
      </ResultTableData>
      <ResultTableData>{material.displayName}</ResultTableData>
      <ResultTableDataText>
        <input
          type="checkbox"
          name={materialKey}
          checked={lockedFields[materialKey] || false}
          onChange={handleLockChange}
        />
        {checkedItems[53 + index] ? "0 " :
          <InputResult
            type="number"
            name={materialKey}
            value={calculatedValue}
            onChange={(e) => {
              const newValue = e.target.value;

              // Update the formData with the new value
              setFormData((prevData) => ({
                ...prevData,
                [material.materialName]: newValue
              }));
            }}
            onBlur={() => {
              setLockedFields((prevLocks) => ({
                ...prevLocks,
                [materialKey]: true
              }));
            }}
            onFocus={handleFocus}
          />} units
      </ResultTableDataText>
      <ResultTableData>
        £{checkedItems[53 + index] ? "0.00" : materialPrice.toFixed(2)}
      </ResultTableData>
    </ResultTableRow>
  );
})}



      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue6) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[19] || false}
            onChange={(event) => handleCheckboxChange(event, 19)}
          />
        </ResultTableData>
        <ResultTableData>Decking</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="deckingCalc"
          checked={lockedFields["deckingCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[19] ? "0 " :
          <InputResult
            type="number"
            name="deckingCalc"
            value={formData.deckingCalc === undefined || formData.deckingCalc === null ? "0.00" : formData.deckingCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />} boards
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[19] ? 0.00 : deckingPrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.frameworkCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[20] || false}
            onChange={(event) => handleCheckboxChange(event, 20)}
          />
        </ResultTableData>
        <ResultTableData>Decking frame</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="frameworkCalc"
          checked={lockedFields["frameworkCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[20] ? "0 " :
          <InputResult
            type="number"
            name="frameworkCalc"
            value={formData.frameworkCalc === undefined || formData.frameworkCalc === null ? "0.00" : formData.frameworkCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />} lengths
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[20] ? 0.00 : deckingFramePrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.fixingScrewCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[21] || false}
            onChange={(event) => handleCheckboxChange(event, 21)}
          />
        </ResultTableData>
        <ResultTableData>Fixing screws</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="fixingScrewCalc"
          checked={lockedFields["fixingScrewCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[21] ? "0 " :
          <InputResult
            type="number"
            name="fixingScrewCalc"
            value={formData.fixingScrewCalc === undefined || formData.fixingScrewCalc === null ? "0.00" : formData.fixingScrewCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />} units
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[21] ? 0.00 : fixingScrewPrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.deckingScrewCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[22] || false}
            onChange={(event) => handleCheckboxChange(event, 22)}
          />
        </ResultTableData>
        <ResultTableData>Decking screws</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="deckingScrewCalc"
          checked={lockedFields["deckingScrewCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[22] ? "0 " :
          <InputResult
            type="number"
            name="deckingScrewCalc"
            value={formData.deckingScrewCalc === undefined || formData.deckingScrewCalc === null ? "0.00" : formData.deckingScrewCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />} units
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[22] ? 0.00 : deckingScrewPrice}
          </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue44) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[23] || false}
            onChange={(event) => handleCheckboxChange(event, 23)}
          />
        </ResultTableData>
        <ResultTableData>Decking fascia</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="fasciaCalc"
          checked={lockedFields["fasciaCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[23] ? "0 " :
          <InputResult
            type="number"
            name="fasciaCalc"
            value={formData.fasciaCalc === undefined || formData.fasciaCalc === null ? "0.00" : formData.fasciaCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}   
            onFocus={handleFocus}
          />} boards
        </ResultTableDataText>
        <ResultTableData>
          £{checkedItems[23] ? 0.00 : fasciaPrice}
          </ResultTableData>
      </ResultTableRow>

      </tbody>
      <tbody>
        <TableSeparatorRow></TableSeparatorRow>
      </tbody>
      <tbody>
      
      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.blockworkCalc) === 0)}>
  <ResultTableData>
       <input
        type="checkbox"
        checked={checkedItems[24] || false}
        onChange={(event) => handleCheckboxChange(event, 24)}
      />
  </ResultTableData>
  <ResultTableData>Blockwork</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="blockworkCalc"
          checked={lockedFields["blockworkCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[24] ? "0 " :
          <InputResult
            type="number"
            name="blockworkCalc"
            value={formData.blockworkCalc === undefined || formData.blockworkCalc === null ? "0.00" : formData.blockworkCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />} blocks
       </ResultTableDataText>
       <ResultTableData>
    £{checkedItems[24] ? 0.00 : blockworkPrice}
  </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.brickworkCalc) === 0)}>
        <ResultTableData>
          <input
            type="checkbox"
            checked={checkedItems[25] || false}
            onChange={(event) => handleCheckboxChange(event, 25)}
          />
        </ResultTableData>
        <ResultTableData>Brickwork 
        </ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="brickworkCalc"
          checked={lockedFields["brickworkCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[25] ? "0 " :
          <InputResult
            type="number"
            name="brickworkCalc"
            value={formData.brickworkCalc === undefined || formData.brickworkCalc === null ?  "0.00" : formData.brickworkCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />} bricks
        </ResultTableDataText>
        <ResultTableData>
        £{
      checkedItems[25] 
      ? 0.00
      : brickworkPrice
    }
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.wallMetreCalc) === 0)}>
  <ResultTableData>
    <input
      type="checkbox"
      checked={checkedItems[26] || false}
      onChange={(event) => handleCheckboxChange(event, 26)}
    />
  </ResultTableData>
  <ResultTableData>Cladding</ResultTableData>
  <ResultTableDataText>
  <input
          type="checkbox"
          name="claddingCalc"
          checked={lockedFields["claddingCalc"] || false}
          onChange={handleLockChange}
        />
    {checkedItems[26] ? "0 " :
    <InputResult
      type="number"
      name="claddingCalc"
      value={formData.claddingCalc === undefined || formData.claddingCalc === null ? "0.00" : formData.claddingCalc}
      onChange={handleChange}
      onBlur={handleLockBlur}
      onFocus={handleFocus}
    />}m²
  </ResultTableDataText>
  <ResultTableData>
  £{
      checkedItems[26] 
      ? 0.00
      : claddingPrice
    }
  </ResultTableData>
</ResultTableRow>


      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.tileAdhesiveCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[27] || false}
            onChange={(event) => handleCheckboxChange(event, 27)}
          />
        </ResultTableData>
        <ResultTableData>Tile adhesive</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="tileAdhesiveCalc"
          checked={lockedFields["tileAdhesiveCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[27] ? "0 " :
          <InputResult
            type="number"
            name="tileAdhesiveCalc"
            value={formData.tileAdhesiveCalc === undefined || formData.tileAdhesiveCalc === null ? "0.00" : formData.tileAdhesiveCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}units
        </ResultTableDataText>
        <ResultTableData>
        £{
      checkedItems[27] 
      ? 0.00
      : tileAdhesivePrice
    }
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.stoneFacingCalc) === 0)}>
  <ResultTableData>
    <input
      type="checkbox"
      checked={checkedItems[28] || false}
      onChange={(event) => handleCheckboxChange(event, 28)}
    />
  </ResultTableData>
  <ResultTableData>Stone facing</ResultTableData>
  <ResultTableDataText>
  <input
          type="checkbox"
          name="stoneFacingCalc"
          checked={lockedFields["stoneFacingCalc"] || false}
          onChange={handleLockChange}
        />
    {checkedItems[28] ? "0 " :
    <InputResult
      type="number"
      name="stoneFacingCalc"
      value={formData.stoneFacingCalc === undefined || formData.stoneFacingCalc === null ? "0.00" : formData.stoneFacingCalc}
      onChange={handleChange}
      onBlur={handleLockBlur}
      onFocus={handleFocus}
    />} ton
  </ResultTableDataText>
  <ResultTableData>
  £{
      checkedItems[28] 
      ? 0.00
      : stoneFacingPrice
    }
  </ResultTableData>
</ResultTableRow>

<ResultTableRow hidden={toggleUnusedValues && (Number(formData.renderCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[29] || false}
            onChange={(event) => handleCheckboxChange(event, 29)}
          />
        </ResultTableData>
        <ResultTableData>Render</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="renderCalc"
          checked={lockedFields["renderCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[29] ? "0 " :
          <InputResult
            type="number"
            name="renderCalc"
            value={formData.renderCalc === undefined || formData.renderCalc === null ? "0.00" : formData.renderCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}ton
        </ResultTableDataText>
        <ResultTableData>
        £{
      checkedItems[29] 
      ? 0.00
      : renderPrice
    }
        </ResultTableData>
      </ResultTableRow>

      </tbody>
      <tbody>
        <TableSeparatorRow></TableSeparatorRow>
      </tbody>
      <tbody>


      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue9) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[30] || false}
            onChange={(event) => handleCheckboxChange(event, 30)}
          />
        </ResultTableData>
        <ResultTableData>Channel drains</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="channelDrainCalc"
          checked={lockedFields["channelDrainCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[30] ? "0 " :
          <InputResult
            type="number"
            name="channelDrainCalc"
            value={formData.channelDrainCalc === undefined || formData.channelDrainCalc === null || Number(formData.channelDrainCalc) === 0 ? "0.00" : formData.channelDrainCalc.toString()}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}m
        </ResultTableDataText>
        <ResultTableData>   
        £{checkedItems[30] ? 0.00 : channelDrainPrice}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue10) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[31] || false}
            onChange={(event) => handleCheckboxChange(event, 31)}
          />
        </ResultTableData>
        <ResultTableData>Drainage pipe</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="pipeCalc"
          checked={lockedFields["pipeCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[31] ? "0 " :
          <InputResult
            type="number"
            name="pipeCalc"
            value={formData.pipeCalc === undefined || formData.pipeCalc === null || Number(formData.pipeCalc) === 0 ? "0.00" : formData.pipeCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}m
        </ResultTableDataText>
        <ResultTableData>
        £{checkedItems[31] ? 0.00 : drainagePipePrice}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue11) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[32] || false}
            onChange={(event) => handleCheckboxChange(event, 32)}
          />
        </ResultTableData>
        <ResultTableData>Gully pot</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="gullyCalc"
          checked={lockedFields["gullyCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[32] ? "0 " :
          <InputResult
            type="number"
            name="gullyCalc"
            value={formData.gullyCalc === undefined || formData.gullyCalc === null || Number(formData.gullyCalc) === 0 ? "0.00" : formData.gullyCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />} units
        </ResultTableDataText>
        <ResultTableData>
        £{checkedItems[32] ? 0.00 : gullyPotPrice}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue12) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[33] || false}
            onChange={(event) => handleCheckboxChange(event, 33)}
          />
        </ResultTableData>
        <ResultTableData>Drainage fittings</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="fittingsCalc"
          checked={lockedFields["fittingsCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[33] ? "0 " :
          <InputResult
            type="number"
            name="fittingsCalc"
            value={formData.fittingsCalc === undefined || formData.fittingsCalc === null ? "0.00" : (Number(formData.fittingsCalc) === 0 ? "0.00" : formData.fittingsCalc)}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}units
        </ResultTableDataText>  
        <ResultTableData>
        £{checkedItems[33] ? 0.00 : drainageFittingsPrice}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue13) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[34] || false}
            onChange={(event) => handleCheckboxChange(event, 34)}
          />
        </ResultTableData>
        <ResultTableData>Manhole cover</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="manholeCoverCalc"
          checked={lockedFields["manholeCoverCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[34] ? "0 " :
          <InputResult
            type="number"
            name="manholeCoverCalc"
            value={formData.manholeCoverCalc === undefined || formData.manholeCoverCalc === null || Number(formData.manholeCoverCalc) === 0 ? "0.00" : formData.manholeCoverCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}units
        </ResultTableDataText>
        <ResultTableData>
        £{checkedItems[34] ? 0.00 : manholeCoverPrice}
        </ResultTableData>
      </ResultTableRow>

      </tbody>
      <tbody>
        <TableSeparatorRow></TableSeparatorRow>
      </tbody>
      <tbody>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.sleeperCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[35] || false}
            onChange={(event) => handleCheckboxChange(event, 35)}
          />
        </ResultTableData>
        <ResultTableData>Sleepers</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="sleeperCalc"
          checked={lockedFields["sleeperCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[35] ? "0 " :
          <InputResult
            type="number"
            name="sleeperCalc"
            value={formData.sleeperCalc === undefined || formData.sleeperCalc === null ? "0.00" : formData.sleeperCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}units
        </ResultTableDataText>
        <ResultTableData>
        £{checkedItems[35] ? 0.00 : sleepersPrice}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.gravelCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[36] || false}
            onChange={(event) => handleCheckboxChange(event, 36)}
          />
        </ResultTableData>
        <ResultTableData>Gravel</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="gravelCalc"
          checked={lockedFields["gravelCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[36] ? "0 " :
          <InputResult
            type="number"
            name="gravelCalc"
            value={formData.gravelCalc === undefined || formData.gravelCalc === null ? "0.00" : formData.gravelCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}ton
        </ResultTableDataText>   
        <ResultTableData>
        £{checkedItems[36] ? 0.00 : gravelPrice} 
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.barkCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[37] || false}
            onChange={(event) => handleCheckboxChange(event, 37)}
          />
        </ResultTableData>
        <ResultTableData>Bark</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="barkCalc"
          checked={lockedFields["barkCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[37] ? "0 " :
          <InputResult
            type="number"
            name="barkCalc"
            value={formData.barkCalc === undefined || formData.barkCalc === null ? "0.00" : formData.barkCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}ton
        </ResultTableDataText>   
        <ResultTableData>
        £{checkedItems[37] ? 0.00 : barkPrice} 
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue51) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[38] || false}
            onChange={(event) => handleCheckboxChange(event, 38)}
          />
        </ResultTableData>
        <ResultTableData>Artificial turf</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="artiCalc"
          checked={lockedFields["artiCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[38] ? "0" :
          <InputResult
            type="number"
            name="artiCalc"
            value={formData.artiCalc === undefined || formData.artiCalc === null || Number(formData.artiCalc) === 0 ? "0.00" : formData.artiCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />} m²
        </ResultTableDataText>   
        <ResultTableData>
        £{checkedItems[38] ? 0.00 : artificialTurfPrice} 
        </ResultTableData>
      </ResultTableRow>


      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue19) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[39] || false}
            onChange={(event) => handleCheckboxChange(event, 39)}
          />
        </ResultTableData>
        <ResultTableData>Turf</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="turfCalc"
          checked={lockedFields["turfCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[39] ? "0 " :
          <InputResult
            type="number"
            name="turfCalc"
            value={formData.turfCalc === undefined || formData.turfCalc === null || Number(formData.turfCalc) === 0 ? "0.00" : formData.turfCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}rolls
        </ResultTableDataText>   
        <ResultTableData>
        £{checkedItems[39] ? 0.00 : turfPrice} 
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.soilCalc) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[40] || false}
            onChange={(event) => handleCheckboxChange(event, 40)}
          />
        </ResultTableData>
        <ResultTableData>Soil</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="soilCalc"
          checked={lockedFields["soilCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[40] ? "0 " :
          <InputResult
            type="number"
            name="soilCalc"
            value={formData.soilCalc === undefined || formData.soilCalc === null ? "0.00" : formData.soilCalc}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}ton
        </ResultTableDataText> 
        <ResultTableData>
        £{checkedItems[40] ? 0.00 : soilPrice}
        </ResultTableData>
      </ResultTableRow>

      </tbody>
      <tbody>
        <TableSeparatorRow></TableSeparatorRow>
      </tbody>
      <tbody>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue14) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[41] || false}
            onChange={(event) => handleCheckboxChange(event, 41)}
          />
        </ResultTableData>
        <ResultTableData>Digger</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="diggerCalc"
          checked={lockedFields["diggerCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[41] ? "0 " :
          <InputResult
            type="number"
            name="diggerCalc"
            value={formData.diggerCalc === undefined || formData.diggerCalc === null ? "0.00" : formData.diggerCalc.toString()}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}days
        </ResultTableDataText>
        <ResultTableData>
        £{checkedItems[41] ? 0  : diggerPrice}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue15) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[42] || false}
            onChange={(event) => handleCheckboxChange(event, 42)}
          />
        </ResultTableData>
        <ResultTableData>Dumper</ResultTableData>
        <ResultTableDataText>
        <input
          type="checkbox"
          name="dumperCalc"
          checked={lockedFields["dumperCalc"] || false}
          onChange={handleLockChange}
        />
          {checkedItems[42] ? "0 " :
          <InputResult
            type="number"
            name="dumperCalc"
            value={formData.dumperCalc === undefined || formData.dumperCalc === null ? "0.00" : formData.dumperCalc.toString()}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />}days
        </ResultTableDataText>
        <ResultTableData>
        £{checkedItems[42] ? 0 : dumperPrice}
        </ResultTableData>
      </ResultTableRow>

      </tbody>
      <tbody>
        <TableSeparatorRow></TableSeparatorRow>
      </tbody>
      <tbody>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue53) === 0)}>
      <ResultTableData>
        <input
            type="checkbox"
            checked={checkedItems[45] || false}
            onChange={(event) => handleCheckboxChange(event, 45)}
          />
        </ResultTableData>
        <ResultTableData>Skip</ResultTableData>
        <ResultTableData>
          {checkedItems[45] ? "0 " :
          <InputResult
            type="number"
            name="inputValue53"
            value={formData.inputValue53 === undefined || formData.inputValue53 === null ?  "0.00" : formData.inputValue53}
            onChange={handleChange}
            onBlur={handleLockBlur}
            onFocus={handleFocus}
          />} {formData.inputValue53 <= 1 ? "skip" : "skips"}
        </ResultTableData>
        <ResultTableData>
        £{checkedItems[45] ? 0 : SkipPrice}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue52) === 0)}>
      <ResultTableData>
 
        </ResultTableData>
        <ResultTableData>Other hire</ResultTableData>
        <ResultTableData>
         
        </ResultTableData>
        <ResultTableData>
        £{formData.inputValue52}
        </ResultTableData>
      </ResultTableRow>


        
      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue42) === 0)}>
      <ResultTableData>
      
        </ResultTableData>
        <ResultTableData>Timber</ResultTableData>
        <ResultTableData>
        </ResultTableData>
        <ResultTableData>
          £{formData.inputValue42 ? formData.inputValue42 : 0}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue37) === 0)}>
      <ResultTableData>
       
        </ResultTableData>
        <ResultTableData>Electrical</ResultTableData>
        <ResultTableData>
        
        </ResultTableData>
        <ResultTableData>
          £{formData.inputValue37 ? formData.inputValue37 : 0}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue38) === 0)}>
      <ResultTableData>
      
        </ResultTableData>
        <ResultTableData>Water feature</ResultTableData>
        <ResultTableData>
        
        </ResultTableData>
        <ResultTableData>
          £{formData.inputValue38 ? formData.inputValue38 : 0}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue39) === 0)}>
      <ResultTableData>
      
        </ResultTableData>
        <ResultTableData>Expendables</ResultTableData>
        <ResultTableData>
   
        </ResultTableData>
        <ResultTableData>
        £{formData.inputValue39 ? formData.inputValue39 : 0}
        </ResultTableData>
      </ResultTableRow>

      <ResultTableRow hidden={toggleUnusedValues && (Number(formData.inputValue54) === 0)}>
      <ResultTableData>
      
        </ResultTableData>
        <ResultTableData>Cost of fuel</ResultTableData>
        <ResultTableData>
   
        </ResultTableData>
        <ResultTableData>
        £{formData.inputValue54 ? formData.inputValue54 : 0}
        </ResultTableData>
      </ResultTableRow>
    </tbody>
    <tbody>
    {userAddedItems.map((item, index) => (
      <ResultTableRow key={index}>
        <ResultTableData>
          <input
            type="checkbox"
            checked={item.checked || false}
            onChange={(e) => handleUserAddedItemChange(index, 'checked', e.target.checked)}
          />
        </ResultTableData>
        <ResultTableData>
          <InputResult
            type="text"
            value={item.materialName}
            onChange={(e) => handleUserAddedItemChange(index, 'materialName', e.target.value)}
            placeholder="Material Name"
            style={{ width: '50%' }}
          />
        </ResultTableData>
        <ResultTableData>
          <InputResult
            type="number"
            value={item.quantity}
            onChange={(e) => handleUserAddedItemChange(index, 'quantity', parseFloat(e.target.value))}
            placeholder="Quantity"

          />
          units
        </ResultTableData>
        <ResultTableData>
          £
          <InputResult
            type="number"
            value={item.price}
            onChange={(e) => handleUserAddedItemChange(index, 'price', parseFloat(e.target.value))}
            placeholder="Price"
            style={{ width: '5vh' }}
          />
        </ResultTableData>
      </ResultTableRow>
    ))}

    {/* Row for new material input */}
    <ResultTableRow>
  <ResultTableData></ResultTableData>
  <ResultTableData>
    <InputResult
      type="text"
      value={newMaterial.materialName}
      onChange={(e) => handleNewMaterialChange('materialName', e.target.value)}
      
      onKeyDown={(e) => handleNewMaterialChange('materialName', (e.target as HTMLInputElement).value, e)}
      placeholder="Add New Material"
      style={{ width: '70%' }}
    />
  </ResultTableData>
  <ResultTableData>
    <InputResult
      type="number"
      value={newMaterial.quantity}
      onChange={(e) => handleNewMaterialChange('quantity', parseFloat(e.target.value))}
      onFocus={(e) => {
        if (e.target.value === '0') e.target.value = '';
      }}
      onKeyDown={(e) => handleNewMaterialChange('quantity', (e.target as HTMLInputElement).value, e)}
      placeholder="Quantity"
    />
    units
  </ResultTableData>
  <ResultTableData>
    £
    <InputResult
      type="number"
      value={newMaterial.price}
      onChange={(e) => handleNewMaterialChange('price', parseFloat(e.target.value))}
      onFocus={(e) => {
        if (e.target.value === '0') e.target.value = '';
      }}
      onKeyDown={(e) => handleNewMaterialChange('price', (e.target as HTMLInputElement).value, e)}
      placeholder="Price"
      style={{ width: '5vh' }}
    />
  </ResultTableData>
</ResultTableRow>

  </tbody>
  </ResultTable>
  
  <ResultTable>
  
</ResultTable>




</ResultContainer>
<CostContainer isMinimized={isResultMinimized} isProjectSelected={!!selectedProject}>

<ResultTable ref={table2Ref}>
<tbody>
      <ResultTableRow>
        <ResultTableDataCost>Cost</ResultTableDataCost>
        <ResultTableDataCost>£{Number(totalCost2) || 0}
        </ResultTableDataCost>
      </ResultTableRow>

      <ResultTableRow>
        <ResultTableDataCost>VAT</ResultTableDataCost>
        <ResultTableDataCost>£{Number(vatPrice) || 0}
        </ResultTableDataCost>
      </ResultTableRow>

      <ResultTableRow>
        <ResultTableDataCost>Margin 

          
        <Input
          type="number"
          name="inputValue35"
          value={Number(formData.inputValue35)}
          onChange={handleChange}
          onFocus={(e) => {
            if (e.target.value === '0') e.target.value = '';
          }}
          onClick={() => {
            if (formData.inputValue35 === 0) {
              handleChange({ target: { name: 'inputValue35', value: '' } } as React.ChangeEvent<HTMLInputElement>);
            }
          }}
          style={{ marginLeft: "10px" }}
        />{" "} 
        %
        £{Number(addedMargin) || 0}
        </ResultTableDataCost>
        <ResultTableDataCost>
        £{Number(marginTotal) || 0}
        </ResultTableDataCost>
      </ResultTableRow>

      <ResultTableRow>
        <ResultTableDataCost>Cost of project</ResultTableDataCost>
        <ResultTableDataCost>£{Number(projectPrice).toFixed(2) || 0}
        </ResultTableDataCost>
      </ResultTableRow>   
</tbody>
</ResultTable>
</CostContainer>

</ContainerWrapper>
</TablesContainer>
        </MainContainer>
        <div>
  {(highlightButton === false && (isMaterialDataError || isSettingsDataError || !selectedProject)) && (
    <ErrorMessage>
      { !selectedProject ? "Please Select Project or enter Project name." : "Please save settings and price data." }
    </ErrorMessage>
  )}
</div>

        <ActionContainer>

{isMessageVisible && (
        <span style={{ 
          position: 'fixed', 
          bottom: '20px', 
          left: '50%', 
          transform: 'translateX(-50%)', 
          color: 'rgba(0, 255, 9, 1)', 
          padding: '10px 20px', 
          zIndex: 1000 // Ensure it appears above other elements
        }}>
          {statusMessage}
        </span>
      )}


      {isModalOpen && (
        <ModalOverlay onClick={() => setIsModalOpen(false)}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <ActionButton onClick={() => handleAction('save')}>Save Project</ActionButton>
            <ActionButton onClick={() => handleAction('delete')}>Delete Project</ActionButton>
            <ActionButton onClick={() => handleAction('download')}>Download PDF</ActionButton>
            <ActionButton onClick={() => handleAction('upload')}>Upload Data from Design</ActionButton>
            <ActionButton onClick={() => handleAction('updatePrices')}>Update Prices</ActionButton>
            <ActionButton onClick={() => handleAction('updateSettings')}>Update Settings</ActionButton>
            <ActionButton onClick={() => setIsModalOpen(false)} style={{ backgroundColor: 'grey' }}>
              Close
            </ActionButton>
          </ModalContainer>
        </ModalOverlay>
      )}
              {(highlightButton || selectedProject) && (
        <NewButton style={{ color: isBackgroundWhite ? 'black' : 'white'}} onClick={() => setToggleUnusedValues(!toggleUnusedValues)}>
          {toggleUnusedValues ? "Show unused values" : "Hide unused values"}
        </NewButton>
      )}{(highlightButton || selectedProject) && (
        <HighlightedButton highlighted={highlightButton}
        onClick={() => setIsModalOpen(true)}
        style={{
          color: isBackgroundWhite ? 'white' : 'black',
          backgroundColor: isBackgroundWhite ? 'grey' : 'white',
        }}
      >
        Project Actions
      </HighlightedButton>
      )}
      {saveStatus && (
  <div style={{ position: 'fixed', bottom: '20px', right: '20px', padding: '10px', color: '#fff', borderRadius: '5px', zIndex: 1000 }}>
    {saveStatus}
  </div>
)}

     
      </ActionContainer>
        </ContentWrapper>
        
        )};
    
    export default ProjectComponent;